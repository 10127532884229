import { Cross } from "@/assets/icons/24/Cross";
import { PopperContentProps } from "@radix-ui/react-popover";
import {
  Provider,
  Root,
  Trigger,
  Portal,
  Content,
  Arrow,
} from "@radix-ui/react-tooltip";
import { ReactNode, PropsWithChildren, useState, useEffect } from "react";

type TooltipProps = {
  defaultOpen?: boolean;
  open?: boolean;
  side?: PopperContentProps["side"];
  sideOffset?: number;
  align?: PopperContentProps["align"];
  alignOffset?: number;
  autoCloseAfter?: number;
  closeable?: boolean;
  content: ReactNode;
};

export const Tooltip = ({
  open,
  side,
  sideOffset,
  align,
  alignOffset,
  autoCloseAfter,
  closeable,
  content,
  children,
}: PropsWithChildren<TooltipProps>) => {
  const [openInternal, setOpenInternal] = useState(open);

  /** Update internal state whenever the prop changes. */
  useEffect(() => {
    setOpenInternal(open);
  }, [open]);

  /** If `autoCloseAfter` was passsed, close it after that amount of time. */
  useEffect(() => {
    if (!autoCloseAfter) {
      return;
    }

    const timerId = window.setTimeout(() => {
      setOpenInternal(false);
    }, autoCloseAfter);

    /** Unmounted - cleanup. */
    return () => {
      window.clearTimeout(timerId);
    };
  }, [autoCloseAfter]);

  const handleClose = () => {
    setOpenInternal(false);
  };

  return (
    <Provider>
      <Root open={openInternal} delayDuration={500}>
        <Trigger asChild>{children}</Trigger>
        <Portal>
          <Content
            className="z-[100] rounded-[0.375rem] border border-brand-500 bg-flavour-50 p-4"
            side={side}
            sideOffset={sideOffset}
            align={align}
            alignOffset={alignOffset}
            hideWhenDetached={true}
          >
            {content}
            {closeable && (
              <button className="absolute right-1 top-1" onClick={handleClose}>
                <Cross className="size-5 shrink-0 text-brand-500" />
              </button>
            )}
            <Arrow asChild>
              <div className="absolute -top-3 left-1/2 size-3 -translate-x-1/2 translate-y-1/2 rotate-45 border-b border-r border-brand-500 bg-flavour-50" />
            </Arrow>
          </Content>
        </Portal>
      </Root>
    </Provider>
  );
};
