import {
  CREATE_AD_ROUTE,
  HIW_SELLING_ROUTE,
} from "@/common/static/routes.static";
import { useIs3779ExperiementActive } from "@/features/create-ad/hooks/useIs3779ExperiementActive";
import { useRouter } from "next/router";
import { useCallback } from "react";

export const useCreateAdNavigator = () => {
  const { isActive } = useIs3779ExperiementActive();
  const { push } = useRouter();

  const gotoWizard = useCallback(() => {
    if (isActive) {
      /** Navigate to create ad wizard. */
      push(CREATE_AD_ROUTE);
      return;
    }

    /** Navigate to selling page. */
    push(HIW_SELLING_ROUTE);
  }, [isActive, push]);

  return { gotoWizard };
};
