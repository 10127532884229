import { useExperiment } from "@/experiments/experiment.hooks";

/**
 * Hook for retrieving whether the specific experiment version is active and/or loading. Should be used to implement experiment-specific hooks.
 *
 * NOTE: We should avoid adding custom experiment hooks in this file. Rather, put it
 * somewhere "near" the A/B code - this will make merging and removing of the A/B code easier.
 *
 * @param name Name of the experiment.
 * @param parameter Name of the parameter to check in this experiment.
 * @param defaultValue Value of the parameter to consider as a default value of this experiment, if loading of experiment or getting active parameter value fails.
 * @param activeValue Value of the parameter to check in order to consider this experiment as active.
 *
 * @example
 * export const useIsFooExperimentActive = () => {
 *   // Returns `true` for `isActive` if experiment is active, loaded and `version` is of value `foo-B`.
 *   return useExperimentActive("foo", "version", "foo-A", "foo-B");
 * }
 */
export const useIsExperimentActive = <TVersion>(
  name: string,
  parameter: string,
  defaultValue: TVersion,
  activeValue: TVersion
) => {
  const { isLoading, config } = useExperiment(name);

  const experimentVersion = config.get<TVersion>(parameter, defaultValue);
  const isActive = experimentVersion === activeValue && !isLoading;

  return { isActive, isLoading };
};
