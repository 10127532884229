import { SEARCH_ROUTE } from "@/common/static/routes.static";
import { useHeaderSavedSearches } from "@/components/Header/search/hooks/useHeaderSavedSearches";
import { useHeaderSearchHistory } from "@/components/Header/search/hooks/useHeaderSearchHistory";
import { useHeaderSearchSuggestions } from "@/components/Header/search/hooks/useHeaderSearchSuggestions";
import { getUrlQueryFromSaveSearchItem } from "@/components/Header/search/utils/header-search.utils";
import { useCallback, useEffect, useState } from "react";
import { HeaderSearchItems } from "@/components/Header/search/contracts/header-search.contracts";

export const useHeaderSearchItems = (query?: string) => {
  const [items, setItems] = useState<HeaderSearchItems>({
    historyItems: [],
    savedSearchItems: [],
    suggestionItems: [],
    count: 0,
  });

  const { historyItems, addItem: addHistoryItem } = useHeaderSearchHistory();

  const { savedSearchItems, loading: savedSearchesLoading } =
    useHeaderSavedSearches();

  const { suggestionItems, loading: suggestionsLoading } =
    useHeaderSearchSuggestions(query);

  const loading = savedSearchesLoading || suggestionsLoading;

  useEffect(() => {
    if (loading) {
      return;
    }

    const result: HeaderSearchItems = {
      historyItems: [],
      savedSearchItems: [],
      suggestionItems: [],
      count: 0,
    };

    if (query) {
      result.suggestionItems = suggestionItems.map((item, index) => ({
        index,
        payload: {
          title: item,
          url: `${SEARCH_ROUTE}?query=${encodeURIComponent(item)}`,
        },
      }));

      result.count = result.suggestionItems.length;

      setItems(result);
      return;
    }

    result.historyItems = historyItems.map((item, index) => ({
      index,
      payload: {
        title: item,
        url: `${SEARCH_ROUTE}?query=${encodeURIComponent(item)}`,
      },
    }));

    result.savedSearchItems = savedSearchItems.map((item, index) => ({
      index: result.historyItems.length + index,
      payload: { ...item, url: getUrlQueryFromSaveSearchItem(item) },
    }));

    result.count = result.historyItems.length + result.savedSearchItems.length;

    setItems(result);
  }, [historyItems, loading, query, savedSearchItems, suggestionItems]);

  const getUrl = useCallback(
    (index: number) => {
      const urls = [
        ...items.suggestionItems.map(item => item.payload.url),
        ...items.historyItems.map(item => item.payload.url),
        ...items.savedSearchItems.map(item => item.payload.url),
      ];

      return urls[index];
    },
    [items.historyItems, items.savedSearchItems, items.suggestionItems]
  );

  return {
    items,
    loading,
    addHistoryItem,
    getUrl,
  };
};
