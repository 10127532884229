import {
  AnalyzeProductImageMutation,
  AnalyzeProductImageMutationVariables,
} from "@/Apollo/schema";
import { MutationHookOptions, useMutation } from "@apollo/client";
import mutationGQL from "@/Apollo/mutation/ai/analyzeProductImage.graphql";

export const useAnalyzeProductImage = (
  options?: MutationHookOptions<
    AnalyzeProductImageMutation,
    AnalyzeProductImageMutationVariables
  >
) => {
  return useMutation(mutationGQL, options);
};
