/**
 * Checks whether the passed value is nil (either `null` or `undefined`).
 * We have both variants for use in type-safe filter.
 *
 * @param value Value to check
 */
export const isNil = <T>(value?: T | null): value is undefined | null =>
  value === null || value === undefined;

/**
 * Checks whether the passed value is NOT nil (either `null` or `undefined`).
 * We have both variants for use in type-safe filter.
 *
 * @param value Value to check
 */
export const isNotNil = <T>(value?: T | null): value is T => !isNil(value);

/**
 * Returns a pseudorandom number between 0 and 1, just like {@link Math.random},
 * but with less of a security risk.
 */
export const getRandomNumber = () => {
  const array = new Uint8Array(1);
  const randomValue = crypto.getRandomValues(array)[0];

  if (!randomValue) {
    throw Error("Unable to generate pseudorandom seed value.");
  }

  return randomValue / Math.pow(2, 8);
};

/**
 * Returns the value of the enum key.
 */
export const getEnumValue = <T extends Record<string, string>>(
  enumObj: T,
  key?: string
): T[keyof T] | undefined => {
  if (!key) {
    return;
  }

  const typedKey = key as T[keyof T];

  if (enumObj[typedKey]) {
    return enumObj[typedKey];
  }

  return Object.values(enumObj).includes(typedKey) ? typedKey : undefined;
};

/**
 * Masks phone number.
 *
 * @param phone Phone to mask.
 */
export const maskPhoneNumber = (phone?: string | null) => {
  if (!phone) {
    return "";
  }

  const firstSlice = phone.startsWith("+") ? 6 : 3;
  const firstDigits = phone.slice(0, firstSlice);
  const last2Digits = phone.slice(-2);

  return firstDigits.padEnd(phone.length - 2, "*").concat(last2Digits);
};
