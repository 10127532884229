import { analyticsService } from "@/common/services/analytics/analytics.service";
import { MegaMenuItemCardData } from "@/common/contracts/mega-menu.contracts";
import { MegaMenuCardItemText } from "@/components/mega-menu/MegaMenuCardItemText";
import { MegaMenuCardItemImage } from "@/components/mega-menu/MegaMenuCardItemImage";
import { useMegaMenuState } from "@/components/mega-menu/MegaMenuProvider";
import {
  NavigationMenuItem,
  NavigationMenuLink,
} from "@radix-ui/react-navigation-menu";
import Link from "@whoppah/next/link";

const MegaMenuCardItemDelegator = ({
  item,
}: {
  item: MegaMenuItemCardData;
}) => {
  switch (item.type) {
    case "card_image_left":
      return (
        <>
          <MegaMenuCardItemImage item={item} />
          <MegaMenuCardItemText item={item} />
        </>
      );

    case "card_text":
      return <MegaMenuCardItemText item={item} />;

    case "card_image":
      return <MegaMenuCardItemImage item={item} />;

    case "card_image_right":
      return (
        <>
          <MegaMenuCardItemText item={item} />
          <MegaMenuCardItemImage item={item} />
        </>
      );

    default:
      return null;
  }
};

type MegaMenuCardItemProps = {
  item: MegaMenuItemCardData;
};

export const MegaMenuCardItem = ({ item }: MegaMenuCardItemProps) => {
  const { hideMenu } = useMegaMenuState();

  const handleClick = () => {
    /** Trigger `navigation_menu` event. */
    analyticsService.trackNavigationMenu(item.label);
  };

  return (
    <NavigationMenuItem>
      <Link href={item.href} passHref={true} prefetch={false}>
        <NavigationMenuLink
          className="justify-left flex h-full flex-1 items-center space-x-4 rounded-[0.25rem] border border-grey-300 px-6 py-4 hover:bg-grey-50 focus:outline-none focus:ring-2 focus:ring-curious-200"
          onSelect={hideMenu}
          onClick={handleClick}
        >
          <MegaMenuCardItemDelegator item={item} />
        </NavigationMenuLink>
      </Link>
    </NavigationMenuItem>
  );
};
