import { cva, VariantProps } from "class-variance-authority";
import { ComponentProps } from "react";

const FlavorStyle = cva("font-title", {
  variants: {
    size: {
      small: "text-sm",
      medium: "text-base",
      large: "text-lg",
    },
  },
  defaultVariants: {
    size: "small",
  },
});

type FlavorLabelProps = { as: "label" } & VariantProps<typeof FlavorStyle> &
  ComponentProps<"label">;

type FlavorSpanProps = { as?: "span" } & VariantProps<typeof FlavorStyle> &
  ComponentProps<"span">;

type FlavorProps = FlavorLabelProps | FlavorSpanProps;

export const Flavor = ({ size, className, ...props }: FlavorProps) => {
  if (props.as === "label") {
    return <label className={FlavorStyle({ size, className })} {...props} />;
  }

  return <span className={FlavorStyle({ size, className })} {...props} />;
};
