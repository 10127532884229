import { useMakeInteractive } from "@/common/hooks/html.hooks";
import { cn } from "@/common/utils/css.utils";
import { showModal } from "@/ui/Modal2";
import AnimatedBanner from "@whoppah/components/shared/AnimatedBanner";
import { TFunction, useTranslation } from "next-i18next";
import SparkIcon from "@whoppah/icons/24/Spark";
import { useMemo } from "react";
import ShippingBoxIcon from "@/assets/icons/24/Shipping";
import { SecurityOutlined as SecurityOutlinedIcon } from "@/assets/icons/24/SecurityOutlined";
import { QRCode as QRCodeIcon } from "@/assets/icons/24/QRCode";

const CONTENT_MAP = (t: TFunction) => [
  {
    id: 1,
    jsx: (
      <>
        <ShippingBoxIcon className="mr-1 inline-block h-4 w-4 shrink-0 text-brand-500" />
        <span className="align-middle">
          {t("header:header-banner-courier")}
        </span>
      </>
    ),
  },
  {
    id: 2,
    jsx: (
      <>
        <SecurityOutlinedIcon className="mr-1 inline-block h-4 w-4 shrink-0 text-brand-500" />
        <span className="align-middle">
          {t("header:header-banner-buyer-protection")}
        </span>
      </>
    ),
  },
  {
    id: 3,
    jsx: (
      <>
        <SparkIcon className="mr-1 inline-block h-4 w-4 shrink-0 text-brand-500" />
        <span className="align-middle">
          {t("header:header-banner-curation")}
        </span>
      </>
    ),
  },
  {
    id: 4,
    className: "flex-1 justify-end cursor-pointer",
    onClick: () => {
      showModal("download-app");
    },
    jsx: (
      <>
        <QRCodeIcon className="mr-1 inline-block h-4 w-4 shrink-0 text-brand-500" />
        <span className="align-middle">
          {t("header:header-banner-download-app")}
        </span>
      </>
    ),
  },
];

const HeaderBannerDesktop = () => {
  const { t } = useTranslation();
  const { makeInteractive } = useMakeInteractive("span");

  const content = useMemo(() => CONTENT_MAP(t), [t]);

  return (
    <>
      <div className="hidden h-10 items-center space-x-4 border-t-4 border-t-brand-500 bg-white px-6 py-2 text-center font-title text-sm text-black md:flex lg:px-12 xl:px-24">
        {content.map(content => (
          <span
            key={content.id}
            className={cn("flex items-center", content.className)}
            {...makeInteractive(content.onClick)}
          >
            {content.jsx}
          </span>
        ))}
      </div>
      <div className="hidden border-b border-b-grey-300 opacity-40 md:block" />
    </>
  );
};

const DURATION = 2500; // 2.5 seconds.

const HeaderBannerMobile = () => {
  const { t } = useTranslation();

  const content = useMemo(
    () =>
      CONTENT_MAP(t)
        .map(c => c.jsx)
        .slice(0, 3), // Only first 3 USPs: 0, 1, 2.
    [t]
  );

  return (
    <>
      <div className="bgwhite flex h-10 items-center justify-center space-x-4 overflow-hidden border-t-4 border-t-brand-500 text-center font-title text-sm text-black md:hidden">
        <AnimatedBanner
          enter="w-full transition duration-500"
          enterFrom="opacity-0 -translate-x-full"
          enterTo="opacity-100 translate-x-0"
          leave="w-full transition duration-500"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-full"
          panels={content}
          timeout={DURATION}
        />
      </div>
      <div className="border-b border-b-grey-300 opacity-40" />
    </>
  );
};

const HeaderBanner = {
  Desktop: HeaderBannerDesktop,
  Mobile: HeaderBannerMobile,
};

export default HeaderBanner;
