export const fileToBase64 = (blob: Blob): Promise<string | undefined> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(reader.result.split(",")[1]); // Extract Base64 part only
      } else {
        reject(new Error("FileReader result is not a string."));
      }
    };

    reader.onerror = () => reject(reader.error);

    reader.readAsDataURL(blob);
  });
};
