/**
 * Returns type-safe object keys.
 *
 * @param obj Object to return keys for.
 */
export const objectKeys = <T extends Record<string, unknown>>(
  obj: T
): (keyof T)[] => {
  return Object.keys(obj);
};
