import {
  AnimationRanges,
  AnimationText,
} from "@/features/iris/contracts/iris.contracts";

export const ANIMATIONS: AnimationRanges & AnimationText = {
  busy: {
    segment: [1, 100],
    text: "create-ad:cp-overview-ai-processing",
  },
  done: {
    segment: [101, 203],
    text: "create-ad:cp-overview-ai-done",
  },
  error: {
    segment: [205, 289],
    text: "create-ad:cp-overview-ai-error-description",
  },
};

export const SEARCH_BY_IMAGE_COOKIE_NAME = "searchByImage";
