export const SHIPPING_METHOD_SLUGS = {
  COURIER: "courier",
  REGISTERED: "registered",
  STANDARD: "standard",
  POSTAL: "postal",
  CUSTOM: "custom",
} as const;

export type SHIPPING_METHOD =
  (typeof SHIPPING_METHOD_SLUGS)[keyof typeof SHIPPING_METHOD_SLUGS];

export const SHIPPING_DELIVERY_COUNTRY_STORAGE_KEY =
  "shipping-delivery-country";

// Maximal dimensions of a product in cm that fit a postal package.
export const POSTAL_MAX_PRODUCT_DIMENSIONS = {
  maxWidth: 100,
  maxHeight: 50,
  maxDepth: 50,
};
