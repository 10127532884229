import Image from "@/components/common/Image";
import { MegaMenuMobileActionProps } from "@/components/mega-menu/mobile/MegaMenuMobileActionDelegator";
import { useCreateAdNavigator } from "@/features/create-ad/hooks/useCreateAdNavigator";
import { Flavor } from "@/ui/typography/Flavor";

export const MegaMenuMobileSellAction = ({
  onClick,
  children,
  icon,
  ...props
}: MegaMenuMobileActionProps) => {
  const { gotoWizard } = useCreateAdNavigator();

  const handleClick = () => {
    gotoWizard();
    onClick?.();
  };

  return (
    <li>
      <button
        {...props}
        className="flex items-center space-x-2"
        onClick={handleClick}
      >
        <div className="relative h-4 w-4">
          <Image
            src={icon}
            alt="Sell"
            layout="fill"
            objectFit="contain"
            priority={true}
          />
        </div>
        <Flavor>{children}</Flavor>
      </button>
    </li>
  );
};
