import { useExecAsync } from "@/common/hooks/general.hooks";
import { CreateAdWizardPhotoSchemaType } from "@/features/create-ad/contracts/create-ad.contracts";
import { ALLOWED_PHOTO_MIME_TYPES } from "@/features/create-ad/static/create-ad.static";
import { convertHeicToPng } from "@/features/create-ad/utils/create-ad.utils";
import { useCallback, useEffect, useMemo, useRef } from "react";

type PhotoFile = Omit<File, "type"> & {
  type: CreateAdWizardPhotoSchemaType["type"];
};

const generatePlaceholder = (
  id: string,
  position: number
): CreateAdWizardPhotoSchemaType => ({
  key: `placeholder-${id}`,
  position,
  type: "placeholder",
});

export const useCreateAdWizardPhotos = () => {
  const { current: objectURLsRef } = useRef<string[]>([]);

  const {
    exec,
    isExecuting: convertExecuting,
    error: convertError,
  } = useExecAsync({
    onError: error => {
      console.error("useCreateAdWizardPhotos", error);
    },
  });

  type ResizeOptions = {
    minWidth: number;
    maxWidth: number;
    minHeight: number;
    maxHeight: number;
    quality?: number;
  };

  /** For objectURL cleanup purposes. */
  useEffect(() => {
    /** Unmounted - cleanup. */
    return () => {
      for (const objectURL of objectURLsRef) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, [objectURLsRef]);

  const resize = useCallback(
    (
      file: File,
      options: ResizeOptions = {
        minWidth: 768,
        maxWidth: 1920,
        minHeight: 768,
        maxHeight: 1920,
        quality: 0.85,
      }
    ) => {
      const getQuality = (quality?: number) => {
        if (!quality) {
          return 0.85;
        }

        if (quality < 0) {
          return 0;
        }

        if (quality > 1) {
          return 1;
        }

        return quality;
      };

      return new Promise<File>((resolve, reject) => {
        const image = document.createElement("img");
        const error = new Error("Unable to resize the image.");

        image.onerror = () => {
          reject(error);
        };

        image.onload = () => {
          const landscape = {
            width: options.maxWidth,
            height: options.minHeight,
          };

          const portrait = {
            width: options.minWidth,
            height: options.maxHeight,
          };

          let { width, height } = image;
          const ratio = width / height;

          const target = ratio >= 1 ? landscape : portrait;
          const scale = Math.min(target.width / width, target.height / height);

          width = Math.round(width * scale);
          height = Math.round(height * scale);

          const canvas = document.createElement("canvas");
          canvas.width = width;
          canvas.height = height;

          const context2d = canvas.getContext("2d");

          if (!context2d) {
            reject(error);
            return;
          }

          context2d.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            blob => {
              if (!blob) {
                reject(error);
                return;
              }

              const name = file.name;
              const nameComponents = name.split(".");
              const resizedName = `${nameComponents[0]}.jpeg`;

              resolve(new File([blob], resizedName, { type: "image/jpeg" }));
            },
            "image/jpeg",
            getQuality(options.quality)
          );
        };

        image.src = URL.createObjectURL(file);
      });
    },
    []
  );

  const convert = useCallback(
    (files?: FileList | null) => {
      return exec(async () => {
        if (!files) {
          return [];
        }

        const isAllowedFile = (file: File): file is PhotoFile => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return ALLOWED_PHOTO_MIME_TYPES.includes(file.type as any);
        };

        const allowedFiles = Array.from(files).filter(isAllowedFile);

        /** Convert files, if needed (only HEIC files are actually converted). */
        const convertedFiles = await Promise.all(
          allowedFiles.map(convertHeicToPng)
        );

        /** Resize files up-to 1920x1920 JPEGs, 0.85 quality. */
        const resizedFiles = await Promise.all(
          convertedFiles.map(file => resize(file))
        );

        return resizedFiles.map<CreateAdWizardPhotoSchemaType>(file => {
          const objectUrl = URL.createObjectURL(file);
          objectURLsRef.push(objectUrl);

          return {
            key: `${Date.now()}-${file.name}`,
            type: file.type as CreateAdWizardPhotoSchemaType["type"],
            url: objectUrl,
            payload: file,
          };
        });
      });
    },
    [exec, objectURLsRef, resize]
  );

  const defaultValues = useMemo<CreateAdWizardPhotoSchemaType[]>(() => {
    return Array.from({ length: 5 }).map((_, i) =>
      generatePlaceholder(`${i}`, i)
    );
  }, []);

  const insert = useCallback(
    (
      photos: CreateAdWizardPhotoSchemaType[],
      no: number,
      inserted: CreateAdWizardPhotoSchemaType[]
    ) => {
      const firstPlaceholderIndex = photos.findIndex(
        photo => photo.type === "placeholder"
      );

      const index = no - 1;

      const insertIndex =
        firstPlaceholderIndex !== -1
          ? firstPlaceholderIndex < index
            ? firstPlaceholderIndex
            : index
          : index;

      const aggregatedPhotos = [
        ...photos.slice(0, insertIndex),
        ...inserted.map((photo, index) => ({
          ...photo,
          position: insertIndex + index,
        })),
        ...photos
          .slice(insertIndex + 1)
          .filter(photo => photo.type !== "placeholder")
          .map((photo, index) => ({
            ...photo,
            position: insertIndex + index + inserted.length,
          })),
      ];

      const fill =
        aggregatedPhotos.length >= 5 ? 1 : 5 - aggregatedPhotos.length;

      return [
        ...aggregatedPhotos,
        ...Array.from({ length: fill }).map((_, i) =>
          generatePlaceholder(
            `${aggregatedPhotos.length + i}`,
            aggregatedPhotos.length + i
          )
        ),
      ];
    },
    []
  );

  const remove = useCallback(
    (photos: CreateAdWizardPhotoSchemaType[], no: number) => {
      const index = no - 1;
      return photos.filter((photo, idx) => idx !== index);
    },
    []
  );

  return {
    convert,
    resize,
    convertExecuting,
    convertError,
    defaultValues,
    insert,
    remove,
  };
};
