import Head from "next/head";
import { useRouter } from "next/router";
import { CANONICAL_DOMAIN } from "../../config";
import removeLocaleFromPath from "../../utils/removeLocaleFromPath";
import localizeHref from "../../../lib/multilingual/localizeHref";
import { cleanSearchParams } from "@/common/utils/router.utils";
import { removeTrailingSlash } from "@/utils/removeTrailingSlash";

// Not sure the best way for this but this works for now.
const getCanonicalUrl = path => {
  const url = new URL(path, `https://${CANONICAL_DOMAIN}`);

  // Clean search params
  cleanSearchParams(url.searchParams);

  // We remove all search params from canonical url.
  // PS - Removal of search and nextInternalLocale (which are added because of nextjs
  // shenanigans) should be kept separate as so in case this implementation is changed,
  // we still remember to remove them.
  for (const key of url.searchParams.keys()) {
    // We keep the page param in canonical url as it is recommended by google.
    // @see https://developers.google.com/search/docs/specialty/ecommerce/pagination-and-incremental-page-loading#use-urls-correctly
    if (key === "page") {
      continue;
    }
    url.searchParams.delete(key);
  }

  return removeTrailingSlash(url.href);
};

const Canonical = ({ fromDefaultLocale = true }) => {
  const router = useRouter();
  const { locale, defaultLocale, route } = router;

  if (route === "/404") {
    return null;
  }

  const asPath = router.asPath === "/" ? "" : router.asPath;

  if (asPath.match(/\[.*\]/) !== null) {
    return null;
  }

  const newAsPath = localizeHref(
    removeLocaleFromPath(router.asPath),
    fromDefaultLocale ? router.defaultLocale : router.locale,
    router.locale
  );
  const path = locale !== defaultLocale ? `${locale}${newAsPath}` : newAsPath;

  const canonicalUrl = getCanonicalUrl(path);

  return (
    <Head>
      <link key="canonical" rel="canonical" href={canonicalUrl} />
    </Head>
  );
};

export default Canonical;
