import {
  SEARCH_FILTER_AR,
  SEARCH_FILTER_AVAILABILITY,
  SEARCH_FILTER_BIDDING,
  SEARCH_FILTER_BRAND,
  SEARCH_FILTER_CATEGORY,
  SEARCH_FILTER_COLOR,
  SEARCH_FILTER_COUNTRY,
  SEARCH_FILTER_DEPTH,
  SEARCH_FILTER_HEIGHT,
  SEARCH_FILTER_MATERIAL,
  SEARCH_FILTER_PRICE,
  SEARCH_FILTER_QUALITY,
  SEARCH_FILTER_STYLE,
  // SEARCH_FILTER_SHAPE,
  SEARCH_FILTER_WIDTH,
  SEARCH_FILTER_PREMIUM,
  SEARCH_FILTER_DISCOUNT_PERCENTAGE,
  SEARCH_FILTER_SHIPPING_METHOD,
  SEARCH_FILTER_NUMBER_OF_ITEMS,
  SEARCH_FILTER_ART_SIZE,
  SEARCH_FILTER_ART_ORIENTATION,
  SEARCH_FILTER_CONDITION,
  SEARCH_FILTER_DESIGNER,
} from "components/search/SearchConstants";

export const PAGE_SIZE = 48;

export const DEFAULT_FILTER_DATA = {
  sort: null,
  order: null,
  minPrice: null,
  maxPrice: null,
  categories: [],
  quality: [],
  brands: [],
  styles: [],
  materials: [],
  colors: [],
  designers: [],
  // shapes: [],
  countries: [],
  sizes: [],
  orientations: [],
  minDepth: null,
  maxDepth: null,
  minHeight: null,
  maxHeight: null,
  minWidth: null,
  maxWidth: null,
  bidding: null,
  availability: null,
  ar: null,
  premium: null,
  discount: null,
  shipping: null,
  items: null,
  condition: null,
};

export const getSearchFilterData = filters => {
  return (
    filters?.reduce(
      (filterData, filter) => {
        if (filter.key === SEARCH_FILTER_PRICE && filter.value) {
          const prices = filter.value.split(",");
          filterData.minPrice = prices[0] && parseFloat(prices[0]);
          filterData.maxPrice = prices[1] && parseFloat(prices[1]);
        }

        if (filter.key === SEARCH_FILTER_CATEGORY && filter.value) {
          filterData.categories = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_QUALITY && filter.value) {
          filterData.quality = filter.value
            .split(",")
            .map(v => v.toLocaleLowerCase());
        }

        if (filter.key === SEARCH_FILTER_BRAND && filter.value) {
          filterData.brands = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_STYLE && filter.value) {
          filterData.styles = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_MATERIAL && filter.value) {
          filterData.materials = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_COLOR && filter.value) {
          filterData.colors = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_DESIGNER && filter.value) {
          filterData.designers = filter.value.split(",");
        }

        // if (filter.key === SEARCH_FILTER_SHAPE && filter.value) {
        //   filterData.shapes = filter.value.split(",");
        // }

        if (filter.key === SEARCH_FILTER_COUNTRY && filter.value) {
          filterData.countries = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_ART_SIZE && filter.value) {
          filterData.sizes = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_ART_ORIENTATION && filter.value) {
          filterData.orientations = filter.value.split(",");
        }

        if (filter.key === SEARCH_FILTER_DEPTH && filter.value) {
          const depth = filter.value.split(",");
          filterData.minDepth = depth[0] && parseFloat(depth[0]);
          filterData.maxDepth = depth[1] && parseFloat(depth[1]);
        }

        if (filter.key === SEARCH_FILTER_HEIGHT && filter.value) {
          const height = filter.value.split(",");
          filterData.minHeight = height[0] && parseFloat(height[0]);
          filterData.maxHeight = height[1] && parseFloat(height[1]);
        }

        if (filter.key === SEARCH_FILTER_WIDTH) {
          const width = filter.value.split(",");
          filterData.minWidth = width[0] && parseFloat(width[0]);
          filterData.maxWidth = width[1] && parseFloat(width[1]);
        }

        if (filter.key === SEARCH_FILTER_BIDDING && filter.value === "true") {
          filterData.bidding = filter.value;
        }

        if (
          filter.key === SEARCH_FILTER_AVAILABILITY &&
          filter.value === "true"
        ) {
          filterData.availability = filter.value;
        }

        if (filter.key === SEARCH_FILTER_AR && filter.value === "true") {
          filterData.ar = filter.value;
        }

        if (filter.key === SEARCH_FILTER_PREMIUM && filter.value === "true") {
          filterData.premium = filter.value;
        }

        if (filter.key === SEARCH_FILTER_DISCOUNT_PERCENTAGE && filter.value) {
          const discount = filter.value.split(",");
          filterData.discount = discount[0];
        }

        if (filter.key === SEARCH_FILTER_SHIPPING_METHOD && filter.value) {
          filterData.shipping = filter.value;
        }

        if (filter.key === SEARCH_FILTER_NUMBER_OF_ITEMS && filter.value) {
          const items = filter.value.split(",");
          filterData.items = items[0];
        }

        if (filter.key === SEARCH_FILTER_CONDITION && filter.value) {
          filterData.condition = filter.value;
        }

        return filterData;
      },
      { ...DEFAULT_FILTER_DATA }
    ) ?? { ...DEFAULT_FILTER_DATA }
  );
};
