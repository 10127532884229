import { useRouter } from "next/router";
import { RefObject, useEffect, useState } from "react";

export const useHeaderSearchQuery = (ref?: RefObject<HTMLInputElement>) => {
  const [query, setQuery] = useState("");
  const router = useRouter();

  useEffect(() => {
    const queryStr = router.query.query;

    if (typeof queryStr === "string") {
      setQuery(queryStr);

      if (!ref?.current) {
        return;
      }

      ref.current.value = queryStr;
    }
  }, [ref, router.query]);

  return { query, setQuery };
};
