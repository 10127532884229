import { useIsExperimentActive } from "@/common/hooks/experiments.hooks";

export const useIs3779ExperiementActive = () => {
  return useIsExperimentActive(
    "3779_how_to_sell_carousel",
    "version",
    "3779-A",
    "3779-B"
  );
};
