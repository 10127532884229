import { IconProps } from "@/common/contracts/icon.contracts";

export const LightBulb = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 11.0249H23V13.0249H20V11.0249ZM1 11.0249H4V13.0249H1V11.0249ZM13 1.0249V4.0249H11V1.0249H13ZM4.92 3.5249L7.05 5.6649L5.63 7.0749L3.5 4.9549L4.92 3.5249ZM16.95 5.6549L19.07 3.5249L20.5 4.9549L18.37 7.0749L16.95 5.6549ZM12 6.0249C13.5913 6.0249 15.1174 6.65704 16.2426 7.78226C17.3679 8.90748 18 10.4336 18 12.0249C18 14.2449 16.79 16.1849 15 17.2249V19.0249C15 19.2901 14.8946 19.5445 14.7071 19.732C14.5196 19.9195 14.2652 20.0249 14 20.0249H10C9.73478 20.0249 9.48043 19.9195 9.29289 19.732C9.10536 19.5445 9 19.2901 9 19.0249V17.2249C7.21 16.1849 6 14.2449 6 12.0249C6 10.4336 6.63214 8.90748 7.75736 7.78226C8.88258 6.65704 10.4087 6.0249 12 6.0249ZM14 21.0249V22.0249C14 22.2901 13.8946 22.5445 13.7071 22.732C13.5196 22.9195 13.2652 23.0249 13 23.0249H11C10.7348 23.0249 10.4804 22.9195 10.2929 22.732C10.1054 22.5445 10 22.2901 10 22.0249V21.0249H14ZM11 18.0249H13V15.8949C14.73 15.4549 16 13.8849 16 12.0249C16 10.964 15.5786 9.94662 14.8284 9.19648C14.0783 8.44633 13.0609 8.0249 12 8.0249C10.9391 8.0249 9.92172 8.44633 9.17157 9.19648C8.42143 9.94662 8 10.964 8 12.0249C8 13.8849 9.27 15.4549 11 15.8949V18.0249Z"
        fill="currentColor"
      />
    </svg>
  );
};
