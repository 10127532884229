import { MegaMenuItemData } from "@/common/contracts/mega-menu.contracts";
import { MegaMenuMobileLanguageAction } from "@/components/mega-menu/mobile/actions/MegaMenuMobileLanguageAction";
import { MegaMenuMobileLogoutAction } from "@/components/mega-menu/mobile/actions/MegaMenuMobileLogoutAction";
import { MegaMenuMobileSellAction } from "@/components/mega-menu/mobile/actions/MegaMenuMobileSellAction";
import { ComponentProps } from "react";

export type MegaMenuMobileActionProps = {
  onClick?: () => void;
  icon?: string | null;
} & Omit<ComponentProps<"button">, "onClick">;

const ACTIONS: Record<
  string,
  (props: MegaMenuMobileActionProps) => JSX.Element
> = {
  ["@logout"]: MegaMenuMobileLogoutAction,
  ["@language"]: MegaMenuMobileLanguageAction,
  ["@sell"]: MegaMenuMobileSellAction,
};

const ACTION_KEYS = Object.keys(ACTIONS);

type MegaMenuMobileActionDelegatorProps = {
  item: MegaMenuItemData;
  onClick?: () => void;
};

export const MegaMenuMobileActionDelegator = ({
  item,
  onClick,
}: MegaMenuMobileActionDelegatorProps) => {
  if (!item.href) {
    return null;
  }

  for (const key of ACTION_KEYS) {
    /** Consider this an action only if it ends with special key. */
    if (item.href.endsWith(key)) {
      const Component = ACTIONS[key];

      if (Component) {
        return (
          <Component icon={item.icon} onClick={onClick}>
            {item.label}
          </Component>
        );
      }
    }
  }

  return null;
};
