import { SearchFilterKey, SearchProductsQueryVariables } from "@/Apollo/schema";
import { MasterFilter } from "@/features/master-filter/contracts/master-filter.contracts";
import { cloneDeep } from "lodash-es";
import { GetServerSidePropsContext } from "next/types";

export const getMasterFilterProps = async (
  context: GetServerSidePropsContext
) => {
  const { "master-filter": filter } = context.req.cookies;
  return { filter };
};

export const updateSearchQueryVariables = (
  variables?: SearchProductsQueryVariables,
  masterFilter?: MasterFilter
) => {
  const copy = cloneDeep(variables);

  if (!copy?.input) {
    return copy;
  }

  const add = masterFilter === "designer";
  const remove = !add;

  if (!copy.input.filters) {
    copy.input.filters = [];
  }

  /** Add */
  if (add) {
    copy.input.filters.push({
      key: SearchFilterKey.IsDesignerItem,
      value: "true",
    });
  }

  /** Remove */
  if (remove) {
    copy.input.filters = copy.input.filters.filter(
      filter => filter?.key !== SearchFilterKey.IsDesignerItem
    );
  }

  return copy;
};
