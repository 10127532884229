import { Transition } from "@headlessui/react";
import Link from "@whoppah/next/link";
import { useRouter, Locale } from "next/router";
import { useCallback, useState } from "react";
import { ChevronDown as ChevronDownIcon } from "@whoppah/icons/24/ChevronDown";
import { useAuth } from "@/auth";
import { cn } from "@/common/utils/css.utils";
import { useHeaderLanguageSwitcherContext } from "./HeaderLanguageSwitcherContext";
import { cleanSearchParamsFromUrl } from "@/common/utils/router.utils";
import removeLocaleFromPath from "@/utils/removeLocaleFromPath";

/**
 * Header language switcher.
 */
const HeaderLanguageSwitcher = ({ focused = false }) => {
  const [isOpen, setIsOpen] = useState(false);

  const router = useRouter();
  const { isLoggedIn } = useAuth();
  const { urls } = useHeaderLanguageSwitcherContext();

  const openMenu = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleMouseEnter = () => {
    openMenu();
  };

  const handleMouseLeave = () => {
    closeMenu();
  };

  const handleClick = () => {
    openMenu();
  };

  const handleLanguageChange =
    (locale: Locale) => (evt: React.MouseEvent<HTMLAnchorElement>) => {
      evt.stopPropagation();
      closeMenu();

      if (!isLoggedIn) {
        return;
      }

      if (router.locale === locale) {
        evt.preventDefault();
      }
    };

  return (
    <div
      className={cn(
        "group cursor-pointer pb-4 font-title text-sm font-semibold uppercase",
        focused && "mt-4"
      )}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      <span className="flex group-hover:text-attention-500">
        <span>{router.locale}</span>
        <ChevronDownIcon className="h-5 w-5" />
      </span>

      <Transition
        show={isOpen}
        unmount={false}
        enter="transition ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <ul className="absolute flex -translate-x-6 flex-col space-y-2 border bg-white p-5 md:top-full">
          {router.locales?.map(locale => {
            const path = urls?.[locale] ?? removeLocaleFromPath(router.asPath);
            const href = cleanSearchParamsFromUrl(path);
            return (
              <li
                key={locale}
                className="font-title text-sm text-grey-700 hover:text-attention-500"
              >
                <Link href={href} locale={locale} prefetch={false} passHref>
                  <a
                    className={
                      router.locale === locale
                        ? "text-attention-50-500"
                        : undefined
                    }
                    lang={locale}
                    hrefLang={locale}
                    onClick={handleLanguageChange(locale)}
                  >
                    {locale}
                  </a>
                </Link>
              </li>
            );
          })}
        </ul>
      </Transition>
    </div>
  );
};

export default HeaderLanguageSwitcher;
