import { useEffect } from "react";
import NiceModal from "@ebay/nice-modal-react";
import dynamic from "next/dynamic";

const SearchSaveModal = dynamic(() =>
  import("@/components/search/save/SearchSaveModal")
);

const SearchSaveSuccessModal = dynamic(() =>
  import("@/components/search/save/SearchSaveSuccessModal")
);

const GuestAccountModal = dynamic(() =>
  import("@/components/Account/GuestAccountModal")
);

const SearchDeleteConfirmModal = dynamic(() =>
  import("@/components/Account/search/SearchDeleteConfirmModal")
);

const AdDeleteModal = dynamic(() =>
  import("@/components/Account/ads/AdDeleteModal").then(
    module => module.AdDeleteModal
  )
);

const AboutFeesModal = dynamic(() =>
  import("@/components/HowItWorks/AboutFeesModal")
);

const IncorrectUserLoggedInModal = dynamic(() =>
  import("@/components/reviews-and-ratings/IncorrectUserLoggedInModal")
);

const PromotionInfoModal = dynamic(() =>
  import("@/scenes/auth/register/PromotionInfoModal")
);

const BiddingModal = dynamic(() =>
  import("@/components/bidding/bidding-modal/BiddingModal").then(
    module => module.BiddingModal
  )
);

const AboutBiddingModal = dynamic(() =>
  import("../components/bidding/about-bidding-modal/AboutBiddingModal").then(
    module => module.AboutBiddingModal
  )
);

const CancelBidModal = dynamic(() =>
  import("@/components/bidding/cancel-bid-modal/CancelBidModal").then(
    module => module.CancelBidModal
  )
);

const MerchantReviewsModal = dynamic(() =>
  import("@/components/merchant/merchant-reviews-modal/MerchantReviewsModal")
);

const ProductDetailsAskSellerModal = dynamic(() =>
  import("@/components/ProductDetail/ProductDetailsAskSellerModal")
);

const ChangeAddressModal = dynamic(() =>
  import("@/components/common/address/ChangeAddressModal")
);

const ProductDetailsArModal = dynamic(
  () =>
    import("@/components/ProductDetail/ProductDetailsArModal").then(
      module => module.ProductDetailsArModal
    ),
  { ssr: false }
);

const CreateAdPhotoTipsModal = dynamic(
  () => import("@/components/ads/create-ad/modals/CreateAdPhotoTipsModal"),
  { ssr: false }
);

const CreateAdShippingInfoModal = dynamic(
  import("@/components/ads/create-ad/modals/CreateAdShippingInfoModal").then(
    module => module.CreateAdShippingInfoModal
  )
);

const SignUpWallModal = dynamic(() =>
  import("@/components/common/SignUpWallModal")
);

const AdsFilterModal = dynamic(() =>
  import("@/components/Account/ads/AdsFilterModal")
);

const AdInformationModal = dynamic(() =>
  import("@/components/Account/ads/AdInformationModal")
);

const AddEditAddressModal = dynamic(() =>
  import("@/components/common/address/AddEditAddressModal")
);

const PaymentInformationModal = dynamic(() =>
  import("@/components/Account/PaymentReview/payments/PaymentInformationModal")
);

const PurchaseInformationModal = dynamic(() =>
  import(
    "@/components/Account/PaymentReview/purchases/PurchaseInformationModal"
  )
);

const ProductDetailsAddToCartModal = dynamic(() =>
  import("@/components/ProductDetail/ProductDetailsAddToCartModal")
);

const CheckoutPromoCodeModal = dynamic(() =>
  import("@/components/checkout2/checkout/CheckoutPromoCodeModal")
);

const CartServiceDescriptionModal = dynamic(() =>
  import("@/components/checkout2/cart/user/CartServiceDescriptionModal")
);

const ProductDetailsModal = dynamic(() =>
  import("@/components/ProductDetail/ProductDetailsModal")
);

const AdBoostsModal = dynamic(() =>
  import("@/components/Account/ads/AdBoostsModal")
);

const AdPriceModal = dynamic(() =>
  import("@/components/Account/ads/AdPriceModal")
);

const ReportModal = dynamic(() =>
  import("@/components/reports/report-modal/ReportModal").then(
    module => module.ReportModal
  )
);

const ConfirmationDialog = dynamic(() =>
  import("@/components/confirmation-dialog/ConfirmationDialog").then(
    module => module.ConfirmationDialog
  )
);

const SignupLoginWallModal = dynamic(() =>
  import("@/components/common/signup-login-wall/SignupLoginWallModal").then(
    module => module.SignupLoginWallModal
  )
);

const ProductDetailsAskSellerConfirmationModal = dynamic(() =>
  import(
    "@/components/ProductDetail/ProductDetailsAskSellerConfirmationModal"
  ).then(module => module.ProductDetailsAskSellerConfirmationModal)
);

const BiddingConfirmationModal = dynamic(() =>
  import("@/components/bidding/bidding-modal/BiddingConfirmationModal").then(
    module => module.BiddingConfirmationModal
  )
);

const ShippingCostsModal = dynamic(() =>
  import(
    "@/components/product/product-shipping-options/shipping-costs-modal/ShippingCostsModal"
  ).then(module => module.ShippingCostsModal)
);

const ProductDetailsSellerBlockModal = dynamic(() =>
  import("@/components/ProductDetail/ProductDetailsSellerBlockModal").then(
    module => module.ProductDetailsSellerBlockModal
  )
);

const EnableVacationModeModal = dynamic(() =>
  import("@/components/Account/vacation-mode/EnableVacationModeModal").then(
    module => module.EnableVacationModeModal
  )
);

const EditVacationModeEndDateModal = dynamic(() =>
  import(
    "@/components/Account/vacation-mode/EditVacationModeEndDateModal"
  ).then(module => module.EditVacationModeEndDateModal)
);

const DisableVacationModeModal = dynamic(() =>
  import("@/components/Account/vacation-mode/DisableVacationModeModal").then(
    module => module.DisableVacationModeModal
  )
);

const AccountSettingsPasswordChangeSuccessModal = dynamic(() =>
  import("@/components/Account/AccountSettingsPasswordChangeSuccessModal").then(
    module => module.AccountSettingsPasswordChangeSuccessModal
  )
);

const SMSWallModal = dynamic(() =>
  import("@/components/common/sms-wall/SMSWallModal").then(
    module => module.SMSWallModal
  )
);

const AppDownloadModal = dynamic(() =>
  import("@/common/components/AppDownloadModal").then(
    module => module.AppDownloadModal
  )
);

const CommonModal = dynamic(() =>
  import("@/components/common/modal/CommonModal").then(
    module => module.CommonModal
  )
);

const VerifyAccountModal = dynamic(() =>
  import("@/features/verify-account/components/VerifyAccountModal").then(
    module => module.VerifyAccountModal
  )
);

const CreateAdOnBoardingModal = dynamic(() =>
  import(
    "@/features/create-ad/components/on-boarding/CreateAdOnBoardingModal"
  ).then(module => module.CreateAdOnBoardingModal)
);

const CreateAdWizardIrisProgress = dynamic(() =>
  import("@/features/iris/components/IrisProgressModal").then(
    module => module.CreateAdWizardIrisProgress
  )
);

const useRegisterModals = () => {
  useEffect(() => {
    NiceModal.register("search-save", SearchSaveModal);
    NiceModal.register("search-save-success", SearchSaveSuccessModal);
    NiceModal.register("guest-account", GuestAccountModal);
    NiceModal.register("search-delete-confirm", SearchDeleteConfirmModal);
    NiceModal.register("ad-delete-modal", AdDeleteModal);
    NiceModal.register("bidding", BiddingModal);
    NiceModal.register("about-bidding", AboutBiddingModal);
    NiceModal.register("cancel-bid", CancelBidModal);
    NiceModal.register("selling-about-fees", AboutFeesModal);
    NiceModal.register("incorrect-user-logged-in", IncorrectUserLoggedInModal);
    NiceModal.register("promotion-info", PromotionInfoModal);
    NiceModal.register("merchant-reviews", MerchantReviewsModal);
    NiceModal.register("ask-seller", ProductDetailsAskSellerModal);
    NiceModal.register("change-address", ChangeAddressModal);
    NiceModal.register("google-ar", ProductDetailsArModal);
    NiceModal.register("create-ad-photo-tips", CreateAdPhotoTipsModal);
    NiceModal.register("create-ad-shipping-info", CreateAdShippingInfoModal);
    NiceModal.register("signup", SignUpWallModal);
    NiceModal.register("ads-filter", AdsFilterModal);
    NiceModal.register("ads-more-information", AdInformationModal);
    NiceModal.register("add-edit-address", AddEditAddressModal);
    NiceModal.register("payment-info", PaymentInformationModal);
    NiceModal.register("purchase-info", PurchaseInformationModal);
    NiceModal.register("pdp-add-to-cart", ProductDetailsAddToCartModal);
    NiceModal.register("checkout-promo-code-modal", CheckoutPromoCodeModal);
    NiceModal.register(
      "cart-service-description-modal",
      CartServiceDescriptionModal
    );
    NiceModal.register("pdp-details-modal", ProductDetailsModal);
    NiceModal.register("ad-boosts", AdBoostsModal);
    NiceModal.register("ad-price", AdPriceModal);
    NiceModal.register("report", ReportModal);
    NiceModal.register("confirmation-dialog", ConfirmationDialog);
    NiceModal.register("signup-login-wall", SignupLoginWallModal);
    NiceModal.register(
      "ask-seller-confirmation",
      ProductDetailsAskSellerConfirmationModal
    );
    NiceModal.register("bidding-confirmation", BiddingConfirmationModal);
    NiceModal.register("shipping-costs", ShippingCostsModal);
    NiceModal.register(
      "pdp-details-seller-block-modal",
      ProductDetailsSellerBlockModal
    );
    NiceModal.register("enable-vacation-mode", EnableVacationModeModal);
    NiceModal.register(
      "edit-vacation-mode-end-date",
      EditVacationModeEndDateModal
    );
    NiceModal.register("disable-vacation-mode", DisableVacationModeModal);
    NiceModal.register(
      "password-change-success",
      AccountSettingsPasswordChangeSuccessModal
    );
    NiceModal.register("sms-wall", SMSWallModal);
    NiceModal.register("download-app", AppDownloadModal);
    NiceModal.register("common", CommonModal);
    NiceModal.register("verify-account", VerifyAccountModal);
    NiceModal.register("create-ad-on-boarding", CreateAdOnBoardingModal);
    NiceModal.register("create-ad-iris-progress", CreateAdWizardIrisProgress);
  }, []);
};

export default useRegisterModals;
