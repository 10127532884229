import { useMerchantTOS } from "@/common/hooks/merchant/useMerchantTOS";
import { MasterFilterProvider } from "@/features/master-filter/components/MasterFilterProvider";
import { ApolloProvider } from "@apollo/client";
import NiceModal from "@ebay/nice-modal-react";
import { AuthProvider, useAuth } from "auth";
import Canonical from "components/common/Canonical";
import HrefLang from "components/common/HrefLang";
import MainLayout from "components/Layouts/MainLayout";
import useLogMissingKeys from "hooks/useLogMissingKeys";
import useSmoothScrollOnNavigation from "hooks/useSmoothScrollOnNavigation";
import useTrackPageView from "@/hooks/useTrackPageView";
import { appWithTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo } from "react";
import { defaultFallbackInView } from "react-intersection-observer";
import { MittProvider } from "react-mitt";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/swiper-bundle.min.css";
import { useApollo } from "../lib/apollo/client";
import nextI18NextConfig from "../next-i18next.config.js";
import "../src/components/Account/AccountSettingsPhoneInput.css";
import useRegisterModals from "../src/hooks/useRegisterModals";
import "../src/index.css";
import { poppins, roboto, filsonPro, notoSans } from "@whoppah/fonts/index";
import { useUnreadMessagesCount } from "@/Apollo/hooks/chat/queries/useUnreadMessagesCount";
import { ExperimentsProvider } from "@/experiments/ExperimentsProvider";
import { NoIndex } from "@/common/components/no-index";
import { useExperimentValues } from "@/experiments/experiment.hooks";
import { clearLegacyCookieConsentPopupCookieValueIfExists } from "@/common/utils/cookie.utils";
import { SpeedInsights } from "@vercel/speed-insights/next";
import Script from "next/script";
import { VacationModeBanner } from "@/components/vacation-mode/VacationModeBanner";
import { AppBanner } from "@/components/app-banner/AppBanner";
import { CookiesProvider } from "react-cookie";

// https://github.com/thebuilder/react-intersection-observer#unsupported-fallback
defaultFallbackInView(true);

clearLegacyCookieConsentPopupCookieValueIfExists();

const Toaster = dynamic(() => import("components/common/Toaster"), {
  ssr: false,
});

const getDefaultLayout = (page, props) => {
  const { hideSearch, hideFooter } = props;
  return (
    <MainLayout hideSearch={hideSearch} hideFooter={hideFooter}>
      {page}
    </MainLayout>
  );
};

const Redirect = ({ to, passRedirectUrl }) => {
  const router = useRouter();

  const getActionFromUrl = useCallback(() => {
    const URL_TO_ACTION_MAP = {
      "/create-ad": "create-ad",
      "/account/favorites": "favorite",
      "/account/searches": "saved-search",
    };
    return URL_TO_ACTION_MAP[router.asPath];
  }, [router.asPath]);

  useEffect(() => {
    let pathname = to;
    const action = getActionFromUrl(router.asPath);
    let query = passRedirectUrl
      ? action
        ? { returnUrl: router.asPath, action, referrer: action }
        : { returnUrl: router.asPath }
      : undefined;

    /**
     * If `to` includes "?" we need to split it into `pathname` and `query`, otherwise
     * passing it as `pathname` only will result in incorrect URI encoding of the value.
     */
    if (pathname.includes("?")) {
      const components = pathname.split("?");
      pathname = components[0];
      query = components[1];
    }

    router.replace({ pathname, query });
  }, [router, to, getActionFromUrl, passRedirectUrl]);

  return null;
};

const InnerApp = ({ Component, pageProps }) => {
  const { isLoggedIn, isReady } = useAuth();
  const router = useRouter();

  useLogMissingKeys();
  useSmoothScrollOnNavigation();

  const appWithLayout = useMemo(() => {
    const getLayout = Component.getLayout || getDefaultLayout;
    const app = (
      <>
        {pageProps?.auth === "user" && <NoIndex />}
        <Component {...pageProps} />
        <VacationModeBanner />
      </>
    );
    return getLayout(app, pageProps);
  }, [Component, pageProps]);

  // Don't render auth protected pages if auth isn't ready
  if (!isReady && pageProps?.auth === "user") {
    return <NoIndex />;
  }

  // Redirect to home for guest pages
  if (isLoggedIn && pageProps?.auth === "guest") {
    return <Redirect to={router?.query?.returnUrl ?? "/"} />;
  }

  // Redirect to login for protected pages
  if (!isLoggedIn && pageProps?.auth === "user") {
    return <Redirect to="/account/login" passRedirectUrl />;
  }

  return appWithLayout;
};

// The useTrack[...] hooks are here because they use values from
// providers
const MyApp = props => {
  useTrackPageView();
  useUnreadMessagesCount();
  useRegisterModals();
  useMerchantTOS();

  return (
    <>
      <style jsx global>{`
        :root {
          --font-poppins: ${poppins.style.fontFamily};
          --font-roboto: ${roboto.style.fontFamily};
          --font-noto-sans: ${notoSans.style.fontFamily};
          --font-filson-pro: ${filsonPro.style.fontFamily};
        }
      `}</style>
      <HrefLang />
      <Canonical />
      <NiceModal.Provider>
        <InnerApp {...props} />
      </NiceModal.Provider>
      <AppBanner />
      <Toaster position="bottom-right" />
    </>
  );
};

// The app is translated here so that providers are not remounted when
// language is changed
const TranslatedApp = appWithTranslation(MyApp, nextI18NextConfig);

const Wrapper = props => {
  const client = useApollo(props.pageProps);
  const experimentValues = useExperimentValues(props.pageProps);
  const masterFilter = props.pageProps.filter;

  return (
    <>
      <ApolloProvider client={client}>
        <CookiesProvider defaultSetOptions={{ path: "/" }}>
          <AuthProvider>
            <MittProvider>
              <ExperimentsProvider values={experimentValues}>
                <MasterFilterProvider filter={masterFilter}>
                  <TranslatedApp {...props} />
                </MasterFilterProvider>
              </ExperimentsProvider>
            </MittProvider>
          </AuthProvider>
        </CookiesProvider>
      </ApolloProvider>
      <Script id="debugbear" src="https://cdn.debugbear.com/e4qn0Z7sh332.js" />
      {/**
       * The SpeedInsights component needs to be outside of the providers
       * because otherwise it will throw the following error.
       *
       * {@link} Issue - https://github.com/vercel/speed-insights/issues/35
       */}
      <SpeedInsights />
    </>
  );
};

export default Wrapper;

export { reportWebVitals } from "next-axiom";
