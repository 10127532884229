import { MegaMenuCardList } from "@/components/mega-menu/MegaMenuCardList";
import { MegaMenuHeroImage } from "@/components/mega-menu/MegaMenuHeroImage";
import { MegaMenuLinkItemList } from "@/components/mega-menu/MegaMenuLinkItemList";
import { MegaMenuPanelData } from "@/common/contracts/mega-menu.contracts";
import { Heading } from "@/ui/cva/Heading";
import { MegaMenuSeeAllItem } from "@/components/mega-menu/MegaMenuSeeAllItem";
import { useMemo } from "react";

type MegaMenuPanelMixedProps = {
  title: string;
  data: MegaMenuPanelData;
};

export const MegaMenuPanelMixed = ({
  title,
  data,
}: MegaMenuPanelMixedProps) => {
  const all = useMemo(() => {
    return data.items?.find(item => item.type === "all");
  }, [data.items]);

  const cards = useMemo(() => {
    return data.items?.filter(item => item.type?.startsWith("card"));
  }, [data.items]);

  const links = useMemo(() => {
    return data.items?.filter(item => item.type === "link");
  }, [data.items]);

  return (
    <div className="flex flex-1 flex-col items-start space-y-10">
      <div className="flex items-baseline space-x-2">
        <Heading as="span" size="h3">
          {title}
        </Heading>
        <MegaMenuSeeAllItem item={all} />
      </div>
      <div className="flex space-x-12 self-stretch">
        <MegaMenuCardList
          items={cards}
          className="3xl:grid-cols-[repeat(3,minmax(16rem,1fr))] auto-rows-fr grid-cols-[repeat(2,minmax(16rem,1fr))]"
        />
        <div className="flex min-w-64 flex-1 flex-col items-start space-y-4">
          {links && <MegaMenuLinkItemList items={links} />}
        </div>
        <MegaMenuHeroImage singleImageItem={all} />
      </div>
    </div>
  );
};
