import { PrivateProductFragmentFragment_Product_images_Image } from "@/Apollo/schema";
import { POSTAL_MAX_PRODUCT_DIMENSIONS } from "@/common/static/shipping.static";
import { CreateAdWizardPhotoSchemaType } from "@/features/create-ad/contracts/create-ad.contracts";

type ProductDimensions = {
  width?: number | null;
  height?: number | null;
  depth?: number | null;
};

/**
 * Returns whether package with certain dimensions fits in a specific postal package.
 *
 * @param width Width of a package.
 * @param height Height of a package.
 * @param depth Depth of a package.
 */
export const getProductFitsInPostalPackage = ({
  width,
  height,
  depth,
}: ProductDimensions) => {
  const widthFits = width
    ? width <= POSTAL_MAX_PRODUCT_DIMENSIONS.maxWidth
    : true;

  const heightFits = height
    ? height <= POSTAL_MAX_PRODUCT_DIMENSIONS.maxHeight
    : true;

  const depthFits = depth
    ? depth <= POSTAL_MAX_PRODUCT_DIMENSIONS.maxDepth
    : true;

  return widthFits && heightFits && depthFits;
};

const HEIC_EXT = ".heic";
const PNG_EXT = ".png";
const PNG_MIME = "image/png";

/**
 * Tries to convert input .heic image into a output .png image.
 *
 * If image being converted is not a .heic, it will be returned as is.
 *
 * @param file File to convert.
 */
export const convertHeicToPng = async <F extends File>(file: F) => {
  if (!file.name.toLocaleLowerCase().endsWith(HEIC_EXT)) {
    return file;
  }

  const heic2any = (await import("heic2any")).default;
  const png = (await heic2any({ blob: file, toType: PNG_MIME })) as Blob;

  return new File([png], file.name.replace(HEIC_EXT, PNG_EXT), {
    type: PNG_MIME,
  }) as F;
};

type GetPhotosChanges = {
  deleted: CreateAdWizardPhotoSchemaType[];
  moved: CreateAdWizardPhotoSchemaType[];
  ["new"]: CreateAdWizardPhotoSchemaType[];
};

/**
 * Returns structure indicating changes user made to photos.
 *
 * Structure contains a list of deleted, moved and new photos.
 *
 * @param original List of photos returned by the server.
 * @param updated New list of photos after interacting with the user.
 */
export const getPhotosChanges = (
  original: PrivateProductFragmentFragment_Product_images_Image[],
  updated?: CreateAdWizardPhotoSchemaType[] | null
) => {
  const changes: GetPhotosChanges = {
    deleted: [],
    moved: [],
    new: [],
  };

  if (!updated) {
    return changes;
  }

  /** For quick lookup. */
  const lookup = new Map<string, CreateAdWizardPhotoSchemaType>();

  for (const photo of updated) {
    if (photo.id) {
      /** Photo is from the server. */
      lookup.set(photo.id, photo);
    } else if (photo.type !== "placeholder") {
      /** Photo is newly added photo. */
      changes.new.push(photo);
    }
  }

  for (const photo of original) {
    if (!lookup.has(photo.id)) {
      /** Photo exists in original, but not in updated - this means it has been deleted. */
      changes.deleted.push({
        id: photo.id,
        key: photo.id,
        type: "image/jpeg",
        url: photo.url,
      });
    } else {
      const movedPhoto = lookup.get(photo.id);

      /** Photo exists in original and updated, but with diff position - this means it has been moved. */
      if (movedPhoto && movedPhoto.position !== photo.position) {
        changes.moved.push(movedPhoto);
      }
    }
  }

  return changes;
};
