import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

type MegaMenuContextType = {
  activeMenu: string;
  showMenu: (name: string) => void;
  hideMenu: () => void;
};

const MegaMenuContext = createContext<MegaMenuContextType>({
  activeMenu: "",
  showMenu: () => {},
  hideMenu: () => {},
});

export const MegaMenuProvider = ({ children }: PropsWithChildren) => {
  const [activeMenu, setActiveMenu] = useState("");

  const showMenu = useCallback((name: string) => {
    setActiveMenu(name);
  }, []);

  const hideMenu = useCallback(() => {
    setActiveMenu("");
  }, []);

  const value = useMemo(
    () => ({
      activeMenu,
      showMenu,
      hideMenu,
    }),
    [activeMenu, hideMenu, showMenu]
  );

  return (
    <MegaMenuContext.Provider value={value}>
      {children}
    </MegaMenuContext.Provider>
  );
};

export const useMegaMenuState = () => {
  return useContext(MegaMenuContext);
};
