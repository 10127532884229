import { VisuallyHidden } from "@radix-ui/react-visually-hidden";
import React, { useCallback, useState, memo, useEffect, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogOverlay,
  DialogPortal,
  DialogTitle,
} from "@radix-ui/react-dialog";
import { ChevronLeft } from "@/assets/icons/24/ChevronLeft";
import CrossIcon from "@whoppah/icons/24/line/Cross";
import NiceModal from "@ebay/nice-modal-react";
import { cn } from "@/common/utils/css.utils";

let promiseRef = null;

/**
 * Controller used to show modal. Modal must be implemented using {@link NiceModal.create}.
 *
 * @description Shows modal using {@link NiceModal.show} while keeping track of promises returned so that they are run in sequence (queued).
 *
 * Logic is as follows:
 * - Promise from the first call is assigned to `promiseRef` and returned.
 * - Promise from subsequent calls are wrapped in another Promise, assigned to `promiseRef` and returned so that they are executed only after the previous one is resolved or rejected.
 *
 * @type {{
 *   show: Function
 * }}
 */
const MODAL_CONTROLLER = {
  show: (...args) => {
    if (promiseRef) {
      promiseRef = new Promise((resolve, reject) => {
        promiseRef.then(() => {
          NiceModal.show(...args)
            .then(value => resolve(value))
            .catch(ex => reject(ex));
        });
      });
    } else {
      promiseRef = NiceModal.show(...args);
    }

    return promiseRef;
  },
};

export const showModal = MODAL_CONTROLLER.show;

/**
 *
 * @param modal
 * @param response
 *
 * @returns {() => Promise<any>}
 */
export const handleModalClose =
  (modal, response = undefined) =>
  () => {
    modal.resolve(response);

    const hidePromise = modal.hide();
    modal.resolveHide();

    return hidePromise;
  };

export const handleModalCloseNoop = () => {
  // No-op
};

/**
 * @param {*} modal
 * @param {*} response
 *
 * @returns {Promise<any>}
 */
export const closeModal = (modal, response = undefined) => {
  return handleModalClose(modal, response)();
};

export const MODAL_RESPONSE_YES = "YES";
export const MODAL_RESPONSE_NO = "NO";

let modalContent = null;

/**
 * Controller used to show/hide modal.
 * @deprecated Use {@link showModal} instead.
 *
 * @return {{
 *  modalState: {
 *    isOpen: boolean,
 *    title: string | null
 *  },
 *  openModal: (title: string, content: React.ReactNode) => void,
 *  closeModal: () => void
 * }}
 */
export const useModalController = () => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    title: null,
  });

  const openModal = useCallback((title = undefined, content = undefined) => {
    modalContent = content;

    setModalState({
      isOpen: true,
      title,
    });
  }, []);

  const closeModal = useCallback(() => {
    setModalState(({ title }) => ({
      isOpen: false,
      title,
    }));
  }, []);

  return {
    modalState: modalState,
    openModal,
    closeModal,
  };
};

/**
 * @typedef {object} ModalTitleBarProps
 * @property {boolean} [showBack=false]
 * @property {boolean} [showClose=true]
 * @property {React.ReactElement} [icon]
 * @property {React.ReactNode} [title]
 * @property {() => void} [onBack]
 * @property {() => void} [onClose]
 */

/**
 * Modal title bar.
 *
 * @param showBack
 * @param showClose
 * @param icon
 * @param title
 * @param onBack
 * @param onClose
 * @returns {Element}
 * @constructor
 */
const ModalTitleBar = ({
  showBack,
  showClose,
  icon,
  title,
  onBack,
  onClose,
}) => {
  return (
    <DialogTitle className="flex items-center gap-3">
      <button
        type="button"
        className={cn(
          "flex items-center self-start rounded-full p-1",
          !showBack && "invisible"
        )}
        disabled={!showBack}
        onClick={onBack}
      >
        <ChevronLeft className="h-6 w-6 shrink-0" />
      </button>
      {icon}
      <span className="flex-1 text-center font-title text-lg font-semibold">
        {title}
      </span>
      <button
        type="button"
        className={cn(
          "self-start rounded-full p-1 hover:bg-attention-100 hover:text-attention-500",
          !showClose && "invisible"
        )}
        disabled={!showClose}
        onClick={onClose}
      >
        <CrossIcon className="h-6 w-6 shrink-0" />
      </button>
    </DialogTitle>
  );
};

/**
 * @typedef {object} ModalProps
 * @property {boolean} [open=false]
 * @property {React.ReactElement} [icon]
 * @property {React.ReactNode} [title]
 * @property {string} [className]
 * @property {boolean} [showTitleBar=true]
 * @property {boolean} [showBack=true]
 * @property {boolean} [showClose=true]
 * @property {() => void} [onBack]
 * @property {() => void} [onClose]
 * @property {() => void} [onAfterClose]
 * @property {React.ReactNode} [children]
 */

/**
 * Simple modal.
 *
 * @type {React.FC<ModalProps>}
 */
const Modal = ({
  open = false,
  icon = undefined,
  title = undefined,
  className = undefined,
  showTitleBar = true,
  showBack = false,
  showClose = true,
  onBack = undefined,
  onClose = undefined,
  onAfterClose = undefined,
  children = undefined,
}) => {
  const contentRef = useRef(null);

  const handleBack = useCallback(() => {
    onBack?.();
  }, [onBack]);

  const handleClose = useCallback(() => {
    onClose();
    onAfterClose?.();
  }, [onAfterClose, onClose]);

  const handleClickOutside = useCallback(
    evt => {
      const { current: content } = contentRef;

      if (content && !content.contains(evt.target)) {
        handleClose();
      }
    },
    [contentRef, handleClose]
  );

  useEffect(() => {
    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    /** Unmounted, cleanup. */
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside, open]);

  return (
    <Dialog open={open}>
      <DialogPortal>
        <DialogOverlay className="fixed inset-0 z-[10000] bg-[#000000] opacity-40 data-[state=open]:animate-dialog-overlay-show" />
        <DialogContent
          ref={contentRef}
          className="fixed inset-0 z-[100000] flex items-center justify-center data-[state=open]:animate-dialog-content-show"
          aria-describedby={title}
          onEscapeKeyDown={handleClose}
        >
          <VisuallyHidden asChild>
            <DialogTitle>{title}</DialogTitle>
          </VisuallyHidden>
          <div
            className={cn(
              "z-10 mx-2 flex w-full flex-col space-y-6 rounded-[0.625rem] bg-white p-4 shadow-xl md:mx-0 md:w-auto md:min-w-96 md:p-6",
              className
            )}
            data-dialog-content={true}
          >
            {showTitleBar && (
              <ModalTitleBar
                showBack={showBack}
                showClose={showClose}
                icon={icon}
                title={title}
                onBack={handleBack}
                onClose={handleClose}
              />
            )}
            {children ?? modalContent}
          </div>
        </DialogContent>
      </DialogPortal>
    </Dialog>
  );
};

export default memo(Modal);
