import {
  ComponentProps,
  JSX,
  JSXElementConstructor,
  KeyboardEvent,
  MouseEvent,
  useCallback,
} from "react";

/**
 * Hook that scrolls an element into view.
 *
 * @param options Changes the way the element will be scrolled into view.
 */
export const useScrollElementIntoView = (options?: ScrollIntoViewOptions) => {
  const scroll = useCallback(
    (element?: HTMLElement | null) => {
      if (!element) {
        return;
      }

      const block = options?.block ?? "start";
      const behavior = options?.behavior ?? "smooth";

      element.scrollIntoView({ block, behavior });
    },
    [options?.behavior, options?.block]
  );

  return { scroll };
};

type UseMakeInteractiveOptions = {
  prevendDefault?: boolean;
  stopPropagation?: boolean;
};

/**
 * Returns `register` function that should be used to make element interactive.
 * - `role`
 * - `tabIndex`
 * - `onClick`
 * - `onKeyDown`
 */
export const useMakeInteractive = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>,
>(
  element: T
) => {
  type ReturnType = Pick<
    ComponentProps<typeof element>,
    "role" | "tabIndex" | "onClick" | "onKeyDown"
  >;

  const makeInteractive = useCallback(
    (
      onClick?: () => void,
      options?: UseMakeInteractiveOptions
    ): ReturnType => ({
      role: element === "a" ? "link" : "button",
      tabIndex: 0,
      onClick: (evt: MouseEvent<HTMLElement>) => {
        if (options?.prevendDefault) {
          /** Prevent default behaviour - mostly beneficial for links. */
          evt.preventDefault();
        }

        if (options?.stopPropagation) {
          /** Stop default propagation - mostly beneficial for nested elements. */
          evt.stopPropagation();
        }

        onClick?.();
      },
      onKeyDown: (evt: KeyboardEvent<HTMLElement>) => {
        if (evt.key !== "Enter") {
          return;
        }

        onClick?.();
      },
    }),
    [element]
  );

  return { makeInteractive };
};
