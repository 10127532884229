import { useAnalyzeProductImage } from "@/Apollo/hooks/ai/useAnalyzeProductImage";
import { SEARCH_ROUTE } from "@/common/static/routes.static";
import { fileToBase64 } from "@/common/utils/file.utils";
import { useCreateAdWizardPhotos } from "@/features/create-ad/hooks/useCreateAdWizardPhotos";
import { convertHeicToPng } from "@/features/create-ad/utils/create-ad.utils";
import { SEARCH_BY_IMAGE_COOKIE_NAME } from "@/features/iris/static/iris.static";
import useBeforeUnload from "@/hooks/useBeforeUnload";
import { useRouter } from "next/router";
import { useCallback } from "react";
import { useCookies } from "react-cookie";

export const useHeaderSearchByImage = () => {
  const router = useRouter();
  const { resize } = useCreateAdWizardPhotos();

  const [analyzeProductImage] = useAnalyzeProductImage({
    context: {
      timeout: 20000, // 20s
    },
  });

  const [cookies, setCookie, removeCookie] = useCookies([
    SEARCH_BY_IMAGE_COOKIE_NAME,
  ]);

  /**
   * Remove cookie before unload. This clears cookie on refresh, resulting in us not trying to search by image after.
   */
  useBeforeUnload(() => {
    removeCookie(SEARCH_BY_IMAGE_COOKIE_NAME);
    return false;
  });

  const searchByImage = useCallback(
    async (files: FileList | null) => {
      if (!files) {
        return;
      }

      const file = files.item(0);

      if (!file) {
        return;
      }

      const convertedFile = await convertHeicToPng(file);
      const resizedFile = await resize(convertedFile);
      const image = await fileToBase64(resizedFile);

      const response = await analyzeProductImage({
        variables: {
          input: {
            image,
          },
        },
      });

      const imageId = response.data?.analyzeProductImage?.imageId;

      if (!imageId) {
        return;
      }

      /** Revoke previous ObjectURL. */
      if (cookies.searchByImage) {
        URL.revokeObjectURL(cookies.searchByImage);
      }

      setCookie(SEARCH_BY_IMAGE_COOKIE_NAME, URL.createObjectURL(resizedFile));

      /** Nagivate to the results page. */
      router.push(`${SEARCH_ROUTE}?image=${imageId}`, undefined, {
        scroll: false,
      });
    },
    [analyzeProductImage, cookies.searchByImage, resize, router, setCookie]
  );

  return { searchByImage, removeCookie };
};
