import Head from "next/head";
import { Locale, useRouter } from "next/router";
import localizeHref from "../../../lib/multilingual/localizeHref";
import { CANONICAL_DOMAIN } from "../../config";
import removeLocaleFromPath from "@whoppah/utils/removeLocaleFromPath";
import { cleanSearchParams } from "@/common/utils/router.utils";
import { removeTrailingSlash } from "@/utils/removeTrailingSlash";

const WHITELIST = ["page"];

// LinkHTMLAttributes contains `hrefLang` but in react server render
// camelCase properties remain camelCase. We don't want that for SEO
// purposes. It sucks.
// https://github.com/facebook/react/issues/13162
declare module "react" {
  interface LinkHTMLAttributes<T> extends HTMLAttributes<T> {
    hreflang?: string | undefined;
  }
}

const containsDynamicParam = (url: string): boolean => {
  return url.includes("[") || url.includes("]");
};

const HrefLang = ({ urlMap }: { urlMap: Record<Locale, string> }) => {
  const router = useRouter();
  const { locales, defaultLocale, route } = router;

  // We don't need hreflang for 404 pages
  if (route === "/404") return null;

  const asPath = removeTrailingSlash(router.asPath);

  // If asPath contains dynamic param (which shouldn't happen)
  // we return null because there is no point of adding it to hreflang.
  if (containsDynamicParam(asPath)) return null;

  const getUrl = (locale: Locale) => {
    // If urlMap is passed we get the url from that otherwise we use asPath from router
    const urlPath = urlMap ? urlMap[locale] : router.asPath;

    // Remove locale from path
    const urlPathWithoutLocale = removeLocaleFromPath(urlPath);

    // We localize the path. Will convert `/products` to `/producten`
    const asPath = localizeHref(urlPathWithoutLocale, router.locale, locale);

    // Add locale prefix to path
    const path = locale !== defaultLocale ? `${locale}${asPath}` : asPath;

    // Create url object with canonical domain
    const url = new URL(path, `https://${CANONICAL_DOMAIN}`);

    // Clean search params
    cleanSearchParams(url.searchParams);

    // We remove all the search params that are not in whitelist.
    for (const [key] of url.searchParams) {
      if (!WHITELIST.includes(key)) {
        url.searchParams.delete(key);
      }
    }

    // Return complete url
    return removeTrailingSlash(url.href);
  };

  return (
    <Head>
      <link
        key="default-hreflang"
        rel="alternate"
        hreflang="x-default"
        href={getUrl(defaultLocale)}
      />
      {locales.map(locale => (
        <link
          key={`${locale}-hreflang`}
          rel="alternate"
          hreflang={locale}
          href={getUrl(locale)}
        />
      ))}
    </Head>
  );
};

export default HrefLang;
