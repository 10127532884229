import CameraIcon from "@/assets/icons/24/Camera";
import { LightBulb } from "@/assets/icons/24/LightBulb";
import { useHeaderSearchByImage } from "@/components/Header/search/hooks/useHeaderSearchByImage";
import LokaliseMarkdownText from "@/components/text/LokaliseMarkdownText";
import {
  IrisProgressModal,
  useIrisProgressModal,
} from "@/features/iris/components/IrisProgressModal";
import { Tooltip } from "@/ui/Tooltip";
import { Helper } from "@/ui/typography/Helper";
import { ChangeEvent, useRef } from "react";

type HeaderSearchByImageProps = {
  showTooltip?: boolean;
  onClick?: () => void;
};

export const HeaderSearchByImage = ({
  showTooltip,
  onClick,
}: HeaderSearchByImageProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    open,
    state,
    stateText,
    show: showModal,
    hide: hideModal,
    handleClose: handleCloseModal,
  } = useIrisProgressModal({
    text: {
      busy: { text: "search-by-image:sbi-processing" },
      done: { text: "search-by-image:sbi-done" },
      error: { text: "search-by-image:sbi-error" },
    },
  });

  const { searchByImage } = useHeaderSearchByImage();

  const handleButtonClick = () => {
    inputRef.current?.click();
    onClick?.();
  };

  const handleFileChange = async (evt: ChangeEvent<HTMLInputElement>) => {
    showModal();
    try {
      await searchByImage(evt.target.files);
      evt.target.value = "";
      hideModal("done");
    } catch (ex) {
      hideModal("error");
    }
  };

  const content = (
    <div className="relative flex max-w-60 space-x-1.5 md:max-w-80">
      <LightBulb className="shrink-0 text-brand-500" />
      <Helper className="text-brand-500">
        <LokaliseMarkdownText lokaliseKey="search-by-image:sbi-tooltip" />
      </Helper>
    </div>
  );

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        className="hidden"
        onChange={handleFileChange}
      />
      <Tooltip
        open={showTooltip}
        autoCloseAfter={10000}
        closeable
        side="bottom"
        sideOffset={12}
        align="end"
        alignOffset={-32}
        content={content}
      >
        <button
          type="button"
          className="-mb-2.5 -mr-4 -mt-2.5 flex size-[2.875rem] items-center justify-center border-l border-grey-500 text-grey-400 hover:text-grey-800 disabled:pointer-events-none"
          onClick={handleButtonClick}
        >
          <CameraIcon className="size-[1.125rem]" />
        </button>
      </Tooltip>
      <IrisProgressModal
        open={open}
        state={state}
        stateText={stateText}
        onClose={handleCloseModal}
      />
    </>
  );
};
