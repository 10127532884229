import { analyticsService } from "@/common/services/analytics/analytics.service";
import { cn } from "@/common/utils/css.utils";
import { MegaMenuPanelDelegator } from "@/components/mega-menu/MegaMenuPanelDelegator";
import { useMegaMenuState } from "@/components/mega-menu/MegaMenuProvider";
import { Flavor } from "@/ui/typography/Flavor";
import {
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuContent,
  NavigationMenuTrigger,
} from "@radix-ui/react-navigation-menu";
import Link from "@whoppah/next/link";
import { MouseEvent } from "react";
import { MegaMenuItemData } from "@/common/contracts/mega-menu.contracts";

type MegaMenuHeaderItemSubmenuProps = Omit<MegaMenuItemData, "href">;

const MegaMenuHeaderItemSubmenu = ({
  label,
  submenuSlug,
  ...props
}: MegaMenuHeaderItemSubmenuProps) => {
  const { activeMenu, hideMenu } = useMegaMenuState();

  const handlePointerDownOutside = (evt: Event) => {
    if (label !== activeMenu) {
      return;
    }

    const menuItems = Array.from(
      document.querySelectorAll("[data-mega-menu-items] li")
    );

    const target = evt.target as HTMLElement;

    const shouldPrevent = menuItems.some(
      element =>
        element.contains(target) &&
        target.dataset.megaMenuSubmenuSlug !== "href"
    );

    if (shouldPrevent) {
      evt.preventDefault();
      return;
    }

    hideMenu();
  };

  if (!submenuSlug) {
    return null;
  }

  return (
    <NavigationMenuContent
      forceMount
      onPointerDownOutside={handlePointerDownOutside}
      className={cn(
        "data-[motion=from-end]:animate-menu-show data-[motion=from-start]:animate-menu-show data-[motion=to-end]:animate-menu-hide data-[motion=to-start]:animate-menu-hide peer-data-[state=open]:bg-green-200 [&>div]:outline-none",
        label !== activeMenu && "hidden"
      )}
    >
      <MegaMenuPanelDelegator
        title={label}
        submenuSlug={submenuSlug}
        {...props}
      />
    </NavigationMenuContent>
  );
};

type MegaMenuHeaderItemLinkProps = {
  href: string;
  label: string;
  subMenuSlug: string;
  className?: string;
  onClick?: (evt: MouseEvent<HTMLAnchorElement>) => void;
};

const MegaMenuHeaderItemLink = ({
  href,
  label,
  subMenuSlug,
  className,
  onClick,
}: MegaMenuHeaderItemLinkProps) => {
  const handleClick = (evt: MouseEvent<HTMLAnchorElement>) => {
    onClick?.(evt);

    /** Trigger `navigation_menu` event. */
    analyticsService.trackNavigationMenu(label);
  };

  return (
    <Link href={href} passHref={true} prefetch={false}>
      <NavigationMenuLink
        onClick={handleClick}
        className={cn(
          "grid before:invisible before:h-0 before:font-title before:text-sm before:font-bold before:content-[attr(title)] focus:rounded-[0.25rem] focus:outline-none focus-visible:ring-2 focus-visible:ring-curious-200 focus-visible:ring-offset-4",
          className
        )}
        title={label}
      >
        <Flavor data-mega-menu-submenu-slug={subMenuSlug}>{label}</Flavor>
      </NavigationMenuLink>
    </Link>
  );
};

/**
 * Mega menu header item.
 */
export const MegaMenuHeaderItem = ({
  label,
  href,
  ...props
}: MegaMenuItemData) => {
  const { showMenu, hideMenu } = useMegaMenuState();

  const handleClick =
    (label: string) => (evt: MouseEvent<HTMLAnchorElement>) => {
      if (props.submenuSlug) {
        /** Don't navigate, we want to open the sub-menu. */
        evt.preventDefault();
      }

      /** Activate header item, potentially opening sub-menu. */
      showMenu(label);

      /** Workaround: If the header item does not have a sub-menu, trigger it once more to close the backdrop. */
      if (!props.submenuSlug) {
        hideMenu();
      }
    };

  if (!href) {
    return;
  }

  return (
    <NavigationMenuItem value={label}>
      <NavigationMenuTrigger className="peer hidden" />
      <MegaMenuHeaderItemLink
        href={href}
        label={label}
        subMenuSlug={props.submenuSlug ?? "href"}
        className="whitespace-nowrap peer-data-[state=open]:font-semibold peer-data-[state=open]:text-brand-500"
        onClick={handleClick(label)}
      />
      <MegaMenuHeaderItemSubmenu label={label} {...props} />
    </NavigationMenuItem>
  );
};
