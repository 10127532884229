import {
  AnimationState,
  AnimationText,
} from "@/features/iris/contracts/iris.contracts";
import { ANIMATIONS } from "@/features/iris/static/iris.static";
import Modal from "@/ui/Modal2";
import { Body } from "@/ui/typography/Body";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { useTranslation } from "next-i18next";
import { useCallback, useMemo, useState } from "react";

type IrisProgressModalProps = {
  open?: boolean;
  state: AnimationState;
  stateText: string;
  onClose?: () => void;
};

export const IrisProgressModal = ({
  open,
  state,
  stateText,
  onClose,
}: IrisProgressModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      showClose={state === "error"}
      className="max-w-56 space-y-0 [&>h2]:-mb-4"
      onClose={onClose}
    >
      <div className="pointer-events-none flex flex-col items-center justify-center space-y-4">
        <DotLottieReact
          src="/ai_assistant_animations.lottie"
          loop={state === "busy"}
          autoplay
          segment={ANIMATIONS[state].segment}
          className="size-10"
        />
        <Body className="text-center">{t(stateText)}</Body>
      </div>
    </Modal>
  );
};

type UseIrisProgressModal = {
  hideTimeout?: number;
  text?: AnimationText;
};

export const useIrisProgressModal = (options?: UseIrisProgressModal) => {
  const [open, setOpen] = useState(false);
  const [state, setState] = useState<AnimationState>("busy");

  const stateText = useMemo(() => {
    const text = options?.text;
    return text ? text[state].text : ANIMATIONS[state].text;
  }, [options?.text, state]);

  const show = useCallback(() => setOpen(true), []);

  const hide = useCallback(
    (state: "done" | "error") => {
      setState(state);

      if (state === "error") {
        return;
      }

      const timeout = options?.hideTimeout ?? 2000;

      const id = setTimeout(() => {
        setOpen(false);
        setState("busy");
      }, timeout);

      /** Unmounted - cleanup. */
      return () => {
        clearTimeout(id);
      };
    },
    [options?.hideTimeout]
  );

  const handleClose = useCallback(() => {
    if (state !== "error") {
      return;
    }

    setOpen(false);
    setState("busy");
  }, [state]);

  return { open, state, stateText, show, hide, handleClose };
};
