import { SavedSearchItem } from "@/common/contracts/search.contracts";
import { SEARCH_ROUTE } from "@/common/static/routes.static";
import { getSearchFilterData } from "@/components/search/utils/search.utils";
import { kebabCase } from "lodash-es";

// TODO: Refactor using new filters.
/** Normalize key by converting it to kebabCase + map it to current name. */
const normalizeKey = (key: string) => {
  const normalizedKey = kebabCase(key);

  if (normalizedKey === "categories") {
    return "category";
  }

  if (normalizedKey === "countries") {
    return "country";
  }

  if (normalizedKey === "brands") {
    return "brand";
  }

  if (normalizedKey === "colors") {
    return "color";
  }

  if (normalizedKey === "styles") {
    return "style";
  }

  if (normalizedKey === "materials") {
    return "material";
  }

  return normalizedKey;
};

// TODO: Refactor using new filters.
export const getUrlQueryFromSaveSearchItem = (item: SavedSearchItem) => {
  const url: string[] = [];

  if (item.query) {
    url.push(`query=${item.query}`);
  }

  if (item.filters?.length) {
    const data = getSearchFilterData(item.filters);
    const dataKeys = Object.keys(data);

    for (const key of dataKeys) {
      const filterDataValue = data[key];
      const filterKey = normalizeKey(key);

      if (!filterDataValue) {
        continue;
      }

      if (Array.isArray(filterDataValue)) {
        for (const value of filterDataValue) {
          if (!value) {
            continue;
          }

          url.push(`${filterKey}=${encodeURIComponent(value)}`);
        }

        continue;
      }

      url.push(`${filterKey}=${encodeURIComponent(filterDataValue)}`);
    }
  }

  return [SEARCH_ROUTE, url.join("&")].join("?");
};
