import { analyticsService } from "@/common/services/analytics/analytics.service";
import { ACCOUNT_SEARCHES_ROUTE } from "@/common/static/routes.static";
import { HeaderSearchItem } from "@/components/Header/search/HeaderSearchItem";
import { HeaderSearchSavedSearchItem } from "@/components/Header/search/HeaderSearchSavedSearchItem";
import { LinkButton } from "@/ui/next/LinkButton";
import { Helper } from "@/ui/typography/Helper";
import { ArrowRight as ArrowRightIcon } from "@/assets/icons/24/arrow/ArrowRight";
import { HeaderSearchItems } from "@/components/Header/search/contracts/header-search.contracts";
import { useTranslation } from "next-i18next";
import { MouseEvent } from "react";

type HeaderSearchItemListProps = {
  items: HeaderSearchItems;
  highlightedIndex?: number | null;
  onMouseEnter?: (index: number, evt: MouseEvent<HTMLLIElement>) => void;
  onMouseLeave?: (index: number, evt: MouseEvent<HTMLLIElement>) => void;
  onSelect?: (url: string) => void;
};

export const HeaderSearchItemList = ({
  items,
  highlightedIndex,
  onMouseEnter,
  onMouseLeave,
  onSelect,
}: HeaderSearchItemListProps) => {
  const { t } = useTranslation();

  const handleMouseEnter =
    (index: number) => (evt: MouseEvent<HTMLLIElement>) => {
      onMouseEnter?.(index, evt);
    };

  const handleMouseLeave =
    (index: number) => (evt: MouseEvent<HTMLLIElement>) => {
      onMouseLeave?.(index, evt);
    };

  const handleViewAllSavedSearchesClick = (
    evt: MouseEvent<HTMLAnchorElement>
  ) => {
    /** Trigger `save_alert_search` event. */
    analyticsService.trackSaveAlertSearch();

    /** Don't close the popup. */
    evt.preventDefault();

    /** This will navigate and close the popup. */
    onSelect?.(ACCOUNT_SEARCHES_ROUTE);
  };

  const showSavedSearches = !!items.savedSearchItems.length;

  /**
   * We do go 3 times through the same collection, but the number of items is small
   * and this way the logic stays fairly simple.
   */
  return (
    <ul className="flex flex-col space-y-1">
      {/** Previous searches. */}
      {items.historyItems.map(item => (
        <HeaderSearchItem
          key={item.payload.title}
          title={item.payload.title}
          url={item.payload.url}
          highlight={item.index === highlightedIndex}
          onMouseEnter={handleMouseEnter(item.index)}
          onMouseLeave={handleMouseLeave(item.index)}
          onSelect={onSelect}
        />
      ))}
      {/** Saved searches group title. */}
      {showSavedSearches && (
        <li className="px-3 py-1">
          <Helper className="text-grey-500">
            {t("common:saved-searches-title")}
          </Helper>
        </li>
      )}
      {/** Saved searches. */}
      {items.savedSearchItems.map(item => (
        <HeaderSearchSavedSearchItem
          key={item.payload.id}
          item={item}
          highlightedIndex={highlightedIndex}
          onMouseEnter={handleMouseEnter(item.index)}
          onMouseLeave={handleMouseLeave(item.index)}
          onSelect={onSelect}
        />
      ))}
      {/** View all saved searches. */}
      {showSavedSearches && (
        <LinkButton
          href={ACCOUNT_SEARCHES_ROUTE}
          appearance="minimal"
          className="px-3 py-1 [&>div]:justify-start"
          rightIcon={<ArrowRightIcon className="shrink-0" />}
          onMouseDown={evt => evt.preventDefault()}
          onClick={handleViewAllSavedSearchesClick}
        >
          {t("header:header-view-all-saved-searches")}
        </LinkButton>
      )}
      {/** Search suggestions. */}
      {items.suggestionItems.map(item => (
        <HeaderSearchItem
          key={item.payload.title}
          title={item.payload.title}
          url={item.payload.url}
          highlight={item.index === highlightedIndex}
          onMouseEnter={handleMouseEnter(item.index)}
          onMouseLeave={handleMouseLeave(item.index)}
          onSelect={onSelect}
        />
      ))}
    </ul>
  );
};
