import HeaderLanguageSwitcher from "@/components/Header/HeaderLanguageSwitcher";
import { MegaMenuHeaderItemList } from "@/components/mega-menu/MegaMenuHeaderItemList";
import { useMegaMenuData } from "@/components/mega-menu/hooks/useMegaMenuData";
import {
  MegaMenuProvider,
  useMegaMenuState,
} from "@/components/mega-menu/MegaMenuProvider";
import { MegaMenuViewport } from "@/components/mega-menu/MegaMenuViewport";
import { NavigationMenu } from "@radix-ui/react-navigation-menu";

/**
 * Mega menu "main" entries.
 */
const MegaMenuContent = () => {
  const { data } = useMegaMenuData();
  const { activeMenu } = useMegaMenuState();

  return (
    <NavigationMenu
      value={activeMenu}
      className="flex items-end justify-between"
    >
      <MegaMenuHeaderItemList items={data?.items} />
      <HeaderLanguageSwitcher />
      <MegaMenuViewport />
    </NavigationMenu>
  );
};

export const MegaMenu = () => {
  return (
    <MegaMenuProvider>
      <MegaMenuContent />
    </MegaMenuProvider>
  );
};
