import { cn } from "@/common/utils/css.utils";
import { ReactNode, useEffect, useRef, useState } from "react";

type LabeledSwitchProps<TLeft = string, TRight = string> = {
  leftLabel: string;
  leftIcon?: ReactNode;
  leftValue: TLeft;
  rightLabel: string;
  rightIcon?: ReactNode;
  rightValue: TRight;
  value?: TLeft | TRight;
  className?: string;
  onLeft?: (value: TLeft) => void;
  onRight?: (value: TRight) => void;
};

export const LabeledSwitch = <TLeft = string, TRight = string>({
  leftLabel,
  leftIcon,
  leftValue,
  rightLabel,
  rightIcon,
  rightValue,
  value,
  className,
  onLeft,
  onRight,
}: LabeledSwitchProps<TLeft, TRight>) => {
  const [internalValue, setInternalValue] = useState<TLeft | TRight | null>(
    value ?? null
  );

  const [thumbLeftPosition, setThumbLeftPosition] = useState<number | null>(
    null
  );

  /** We need the width of the left button so that we know by how much to move the thumb to the right. */
  const leftButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setInternalValue(value ?? null);
  }, [value]);

  /** Resize observer. */
  useEffect(() => {
    const obserever = new ResizeObserver(elements => {
      const button = elements.at(0);

      if (button?.target) {
        /** Tailwind class -ml-2.5 is 10px. */
        setThumbLeftPosition(button.target.getBoundingClientRect().width - 10);
      }
    });

    const button = leftButtonRef.current;

    if (button) {
      obserever.observe(button);
    }

    /** Unmounted - cleanup. */
    return () => {
      if (button) {
        obserever.unobserve(button);
      }
    };
  }, []);

  const handleClick = () => {
    const newValue = internalValue === leftValue ? rightValue : leftValue;
    setInternalValue(newValue);

    if (newValue === leftValue) {
      onLeft?.(leftValue);
    }

    if (newValue === rightValue) {
      onRight?.(rightValue);
    }
  };

  const isLeftValue = internalValue === leftValue;
  const isRightValue = internalValue === rightValue;

  const left = isLeftValue ? 0 : thumbLeftPosition ?? undefined;
  const right = !thumbLeftPosition && isRightValue ? 0 : undefined;

  const thumb = (
    <button
      type="button"
      className={cn(
        "absolute whitespace-nowrap rounded-full bg-brand-500 px-4 py-2 font-title text-base font-bold text-white transition-[left]",
        !internalValue && "hidden"
      )}
      style={{
        left,
        right,
      }}
      onClick={handleClick}
    >
      <span className="flex items-center space-x-1 [&>svg]:size-4">
        <span>{internalValue === leftValue ? leftLabel : rightLabel}</span>
        {internalValue === leftValue ? leftIcon : rightIcon}
      </span>
    </button>
  );

  const leftButton = (
    <button
      ref={leftButtonRef}
      type="button"
      className="rounded-full px-4 py-2 font-title text-base"
      onClick={handleClick}
    >
      <span
        title={leftLabel}
        className="grid before:invisible before:h-0 before:overflow-hidden before:font-bold before:content-[attr(title)]"
      >
        <span className="flex items-center space-x-1 [&>svg]:size-4">
          <span>{leftLabel}</span>
          {leftIcon}
        </span>
      </span>
    </button>
  );

  const rightButton = (
    <button
      type="button"
      className="-ml-2.5 rounded-full px-4 py-2 font-title text-base"
      onClick={handleClick}
    >
      <span
        title={rightLabel}
        className="grid before:invisible before:h-0 before:overflow-hidden before:font-bold before:content-[attr(title)]"
      >
        <span className="flex items-center space-x-1 [&>svg]:size-4">
          <span>{rightLabel}</span>
          {rightIcon}
        </span>
      </span>
    </button>
  );

  return (
    <div
      className={cn(
        "relative grid w-max grid-cols-[auto_auto] rounded-full bg-flavour-100",
        className
      )}
    >
      {thumb}
      {leftButton}
      {rightButton}
    </div>
  );
};
