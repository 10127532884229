import { DialogClose } from "@radix-ui/react-dialog";
import { ChevronLeft as ChevronLeftIcon } from "@/assets/icons/24/ChevronLeft";

export const MegaMenuMobileBack = () => {
  return (
    <DialogClose asChild>
      <button className="shrink-0">
        <ChevronLeftIcon />
      </button>
    </DialogClose>
  );
};
