import { PaymentMethod } from "@/Apollo/schema";
import { Device } from "@/common/components/AppDownloadButton";
import { algoliaAnalyticsProvider } from "@/common/services/analytics/providers/algolia-provider";
import { isNil } from "@/common/utils/value.utils";
import { useEffect } from "react";
import {
  AnalyticsLogInStatus,
  AnalyticsMe,
  AnalyticsOrderGroup,
  AnalyticsProduct,
  AnalyticsProvider,
  AnalyticsQuickBidMethod,
  AnalyticsService,
} from "@/common/contracts/analytics.contracts";
import { googleAnalyticsProvider } from "@/common/services/analytics/providers/google-analytics.provider";
import { FieldErrors } from "react-hook-form";
import { ChatMemberRole } from "@/common/contracts/chat.contracts";
import { iterableAnalyticsProvider } from "@/common/services/analytics/providers/iterable-provider";

/**
 * Private registry of all registered service providers.
 */
const PROVIDERS: AnalyticsProvider[] = [
  googleAnalyticsProvider,
  algoliaAnalyticsProvider,
  iterableAnalyticsProvider,
];

/**
 * Helper function that calls specific func on all registered providers.
 *
 * @param func Function identifier.
 * @param args Function arguments.
 */
const callProviderFunc = <TFunc extends keyof AnalyticsProvider>(
  func: TFunc,
  ...args: Parameters<Required<AnalyticsProvider>[TFunc]>
) => {
  for (const provider of PROVIDERS) {
    const providerFunc = provider[func];

    if (providerFunc) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (providerFunc as (...args: any[]) => void)(...args);
    }
  }
};

/**
 * Main analytics service responsible for triggering the same event over all registered providers.
 */
export const analyticsService: AnalyticsService = {
  useExecAfterMount: (func: () => void) => {
    useEffect(() => {
      func();
    }, [func]);
  },
  trackAddToWishlist: (product?: AnalyticsProduct) => {
    if (isNil(product)) {
      return;
    }

    callProviderFunc("trackAddToWishlist", product);
  },
  trackSearch: (query?: string, resultsCount?: number) => {
    if (isNil(query) || isNil(resultsCount)) {
      return;
    }

    callProviderFunc("trackSearch", query, resultsCount);
  },
  trackFormError: (
    formName?: string,
    fieldName?: string,
    errorText?: string
  ) => {
    if (!formName || !fieldName || !errorText) {
      return;
    }

    callProviderFunc("trackFormError", formName, fieldName, errorText);
  },
  trackAllFormErrors: (formName: string, errors: FieldErrors) => {
    for (const key in errors) {
      const keyMessage = errors[key]?.message;
      const message =
        keyMessage && typeof keyMessage === "string" ? keyMessage : "Required";

      /** Trigger `form_error` event. */
      analyticsService.trackFormError(formName, key, message);
    }
  },
  trackClickedWhoppahCourierCheckout: (product?: AnalyticsProduct) => {
    if (isNil(product)) {
      return;
    }

    callProviderFunc("trackClickedWhoppahCourierCheckout", product);
  },
  trackNavigationMenu: (label?: string) => {
    if (isNil(label)) {
      return;
    }

    callProviderFunc("trackNavigationMenu", label);
  },
  trackFooterNavigation: (label?: string) => {
    if (isNil(label)) {
      return;
    }

    callProviderFunc("trackFooterNavigation", label);
  },
  trackSignupLoginSticky: () => {
    callProviderFunc("trackSignupLoginSticky");
  },
  trackLogin: (me?: AnalyticsMe) => {
    if (isNil(me)) {
      return;
    }

    callProviderFunc("trackLogin", me);
  },
  trackLogout: () => {
    callProviderFunc("trackLogout");
  },
  trackViewItemList: (
    listId?: string,
    listName?: string,
    products?: AnalyticsProduct[]
  ) => {
    if (
      isNil(listId) ||
      isNil(listName) ||
      isNil(products) ||
      products.length === 0
    ) {
      return;
    }

    callProviderFunc("trackViewItemList", listId, listName, products);
  },
  trackSelectItem: (
    position?: number,
    listId?: string,
    listName?: string,
    product?: AnalyticsProduct,
    searchMeta?: {
      queryId: string;
      indexName: string;
      page: number;
      limit: number;
    }
  ) => {
    if (
      isNil(position) ||
      isNil(listId) ||
      isNil(listName) ||
      isNil(product) ||
      isNil(searchMeta)
    ) {
      return;
    }

    callProviderFunc(
      "trackSelectItem",
      position,
      listId,
      listName,
      product,
      searchMeta
    );
  },
  trackFilterClickList: (filterType?: string, filterOptions?: unknown) => {
    if (isNil(filterType) || isNil(filterOptions)) {
      return;
    }

    callProviderFunc("trackFilterClickList", filterType, filterOptions);
  },
  trackFilterExpandList: (filterType?: string) => {
    if (isNil(filterType)) {
      return;
    }

    callProviderFunc("trackFilterExpandList", filterType);
  },
  trackFilterResetList: () => {
    callProviderFunc("trackFilterResetList");
  },
  trackSaveAlertList: () => {
    callProviderFunc("trackSaveAlertList");
  },
  trackSaveAlertSearch: () => {
    callProviderFunc("trackSaveAlertSearch");
  },
  trackSortItemsList: (sortType?: string) => {
    if (isNil(sortType)) {
      return;
    }

    callProviderFunc("trackSortItemsList", sortType);
  },
  trackPaginationList: (pageNumber?: number) => {
    if (isNil(pageNumber)) {
      return;
    }

    callProviderFunc("trackPaginationList", pageNumber);
  },
  trackNavigationChipsList: (chipCategory?: string) => {
    if (isNil(chipCategory)) {
      return;
    }

    callProviderFunc("trackNavigationChipsList", chipCategory);
  },
  trackViewItem: (product?: AnalyticsProduct) => {
    if (isNil(product)) {
      return;
    }

    callProviderFunc("trackViewItem", product);
  },
  trackAddToCart: (product?: AnalyticsProduct) => {
    if (isNil(product)) {
      return;
    }

    callProviderFunc("trackAddToCart", product);
  },
  trackShare: () => {
    callProviderFunc("trackShare");
  },
  trackPlaceBidPdp: () => {
    callProviderFunc("trackPlaceBidPdp");
  },
  trackReadMoreBidPdp: () => {
    callProviderFunc("trackReadMoreBidPdp");
  },
  trackPlaceBidSuccessPdp: (
    bidMethod?: AnalyticsQuickBidMethod,
    product?: AnalyticsProduct
  ) => {
    if (isNil(bidMethod) || isNil(product)) {
      return;
    }

    callProviderFunc("trackPlaceBidSuccessPdp", bidMethod, product);
  },
  trackImageZoomPdp: (imageNumber?: number) => {
    if (isNil(imageNumber)) {
      return;
    }

    callProviderFunc("trackImageZoomPdp", imageNumber);
  },
  trackImageBrowsePdp: () => {
    callProviderFunc("trackImageBrowsePdp");
  },
  trackLaunchedArPdp: () => {
    callProviderFunc("trackLaunchedArPdp");
  },
  trackContactSellerPdp: () => {
    callProviderFunc("trackContactSellerPdp");
  },
  trackContactSellerMessageSentPdp: () => {
    callProviderFunc("trackContactSellerMessageSentPdp");
  },
  trackReadMoreBuyerProtectionPdp: () => {
    callProviderFunc("trackReadMoreBuyerProtectionPdp");
  },
  trackReadMoreDeliveryOptionsPdp: () => {
    callProviderFunc("trackReadMoreDeliveryOptionsPdp");
  },
  trackReadMoreParcelPdp: () => {
    callProviderFunc("trackReadMoreParcelPdp");
  },
  trackReadMorePickupPdp: () => {
    callProviderFunc("trackReadMorePickupPdp");
  },
  trackReadMoreCourierPdp: () => {
    callProviderFunc("trackReadMoreCourierPdp");
  },
  trackReadMoreCuratedPdp: () => {
    callProviderFunc("trackReadMoreCuratedPdp");
  },
  trackReadMoreBrengerPdp: () => {
    callProviderFunc("trackReadMoreBrengerPdp");
  },
  trackReadMoreReviewsPdp: () => {
    callProviderFunc("trackReadMoreReviewsPdp");
  },
  trackSimilarItemPdp: () => {
    callProviderFunc("trackSimilarItemPdp");
  },
  trackShowSimilarItemPdp: () => {
    callProviderFunc("trackShowSimilarItemPdp");
  },
  trackShowMoreSimilarItemPdp: () => {
    callProviderFunc("trackShowMoreSimilarItemPdp");
  },
  trackShowSimilarItemPlp: () => {
    callProviderFunc("trackShowSimilarItemPlp");
  },
  trackSeeMoreClickPdp: () => {
    callProviderFunc("trackSeeMoreClickPdp");
  },
  trackClickedUspCard: () => {
    callProviderFunc("trackClickedUspCard");
  },
  trackClickedBrandPromiseBlock: () => {
    callProviderFunc("trackClickedBrandPromiseBlock");
  },
  trackViewCart: (products?: AnalyticsProduct[]) => {
    if (isNil(products) || products.length === 0) {
      return;
    }

    callProviderFunc("trackViewCart", products);
  },
  trackViewCartOverview: (products?: AnalyticsProduct[]) => {
    if (isNil(products) || products.length === 0) {
      return;
    }

    callProviderFunc("trackViewCartOverview", products);
  },
  trackEmptyCartOverview: () => {
    callProviderFunc("trackEmptyCartOverview");
  },
  trackBeingCheckout: (products?: AnalyticsProduct[]) => {
    if (isNil(products) || products.length === 0) {
      return;
    }

    callProviderFunc("trackBeingCheckout", products);
  },
  trackAddShippingInfo: () => {
    callProviderFunc("trackAddShippingInfo");
  },
  trackAddPaymentInfo: (
    products?: AnalyticsProduct[],
    paymentType?: PaymentMethod
  ) => {
    if (isNil(products) || products.length === 0 || isNil(paymentType)) {
      return;
    }

    callProviderFunc("trackAddPaymentInfo", products, paymentType);
  },
  trackClickedPickupCheckout: (product?: AnalyticsProduct) => {
    if (isNil(product)) {
      return;
    }

    callProviderFunc("trackClickedPickupCheckout", product);
  },
  trackClickedExternalBrengerCheckout: (product?: AnalyticsProduct) => {
    if (isNil(product)) {
      return;
    }

    callProviderFunc("trackClickedExternalBrengerCheckout", product);
  },
  trackClickedPostalCheckout: (product?: AnalyticsProduct) => {
    if (isNil(product)) {
      return;
    }

    callProviderFunc("trackClickedPostalCheckout", product);
  },
  trackClickedServiceCheckout: (
    product?: AnalyticsProduct,
    serviceName?: string
  ) => {
    if (isNil(product) || isNil(serviceName)) {
      return;
    }

    callProviderFunc("trackClickedServiceCheckout", product, serviceName);
  },
  trackPurchase: (orderGroup?: AnalyticsOrderGroup) => {
    if (isNil(orderGroup)) {
      return;
    }

    callProviderFunc("trackPurchase", orderGroup);
  },
  trackHpSellNow: () => {
    callProviderFunc("trackHpSellNow");
  },
  trackHpCategoryNavigation: (navigationItem?: string) => {
    if (isNil(navigationItem)) {
      return;
    }

    callProviderFunc("trackHpCategoryNavigation", navigationItem);
  },
  trackHpBrandNavigation: (navigationItem?: string) => {
    if (isNil(navigationItem)) {
      return;
    }

    callProviderFunc("trackHpBrandNavigation", navigationItem);
  },
  trackHpCollectionNavigation: (navigationItem?: string) => {
    if (isNil(navigationItem)) {
      return;
    }

    callProviderFunc("trackHpCollectionNavigation", navigationItem);
  },
  trackHpNewsletterSubscription: () => {
    callProviderFunc("trackHpNewsletterSubscription");
  },
  trackHpReadAllAbout: (articleName?: string) => {
    if (isNil(articleName)) {
      return;
    }

    callProviderFunc("trackHpReadAllAbout", articleName);
  },
  trackHpSocialMediaLinks: (platform?: string) => {
    if (isNil(platform)) {
      return;
    }

    callProviderFunc("trackHpSocialMediaLinks", platform);
  },
  trackHpMarketingBanner: () => {
    callProviderFunc("trackHpMarketingBanner");
  },
  trackHpNewIn: () => {
    callProviderFunc("trackHpNewIn");
  },
  trackHpForYou: () => {
    callProviderFunc("trackHpForYou");
  },
  trackHpFavorites: () => {
    callProviderFunc("trackHpFavorites");
  },
  trackBoostAd: () => {
    callProviderFunc("trackBoostAd");
  },
  trackInviteFriend: () => {
    callProviderFunc("trackInviteFriend");
  },
  trackSignUp: () => {
    callProviderFunc("trackSignUp");
  },
  trackAdCreationCompleted: () => {
    callProviderFunc("trackAdCreationCompleted");
  },
  trackAdCreationClickedPlaceAd: () => {
    callProviderFunc("trackAdCreationClickedPlaceAd");
  },
  trackAdCreationStep: (step?: number) => {
    if (isNil(step)) {
      return;
    }

    callProviderFunc("trackAdCreationStep", step);
  },
  trackPageView: (
    pageUrl?: string,
    pageTitle?: string,
    language?: string,
    me?: AnalyticsMe,
    logInStatus?: AnalyticsLogInStatus
  ) => {
    if (isNil(pageUrl) || isNil(pageTitle) || isNil(language)) {
      return;
    }

    callProviderFunc(
      "trackPageView",
      pageUrl,
      pageTitle,
      language,
      me,
      logInStatus
    );
  },
  trackLoginStart: () => {
    callProviderFunc("trackLoginStart");
  },
  trackSignUpStart: () => {
    callProviderFunc("trackSignUpStart");
  },
  trackSignUpProfileCompleted: () => {
    callProviderFunc("trackSignUpProfileCompleted");
  },
  trackAddToCartChat: () => {
    callProviderFunc("trackAddToCartChat");
  },
  trackPlaceBidChat: () => {
    callProviderFunc("trackPlaceBidChat");
  },
  trackPayNowChat: () => {
    callProviderFunc("trackPayNowChat");
  },
  trackMessageSentChat: () => {
    callProviderFunc("trackMessageSentChat");
  },
  identify: (me?: AnalyticsMe) => {
    if (isNil(me)) {
      return;
    }

    callProviderFunc("identify", me);
  },
  trackExperimentExposure: (
    experimentName?: string,
    experimentGroupName?: string
  ) => {
    if (isNil(experimentName) || isNil(experimentGroupName)) {
      return;
    }

    callProviderFunc(
      "trackExperimentExposure",
      experimentName,
      experimentGroupName
    );
  },
  trackAnnouncementBar: () => {
    callProviderFunc("trackAnnouncementBar");
  },
  trackSignUpBlock: (feature?: string) => {
    if (isNil(feature)) {
      return;
    }

    callProviderFunc("trackSignUpBlock", feature);
  },
  trackCalculateShipping: () => {
    callProviderFunc("trackCalculateShipping");
  },
  trackCalculateShippingSuccess: () => {
    callProviderFunc("trackCalculateShippingSuccess");
  },
  trackCreateAdClickAdsTable: () => {
    callProviderFunc("trackCreateAdClickAdsTable");
  },
  trackCreateAdClickNoAds: () => {
    callProviderFunc("trackCreateAdClickNoAds");
  },
  trackClickedAppInstall: (device?: Device, eventSource?: string) => {
    if (isNil(device)) {
      return;
    }

    callProviderFunc("trackClickedAppInstall", device, eventSource);
  },
  trackChatSellNow: (value?: ChatMemberRole) => {
    if (isNil(value)) {
      return;
    }

    callProviderFunc("trackChatSellNow", value);
  },
  trackClickAppInstallBanner: () => {
    callProviderFunc("trackClickAppInstallBanner");
  },
  trackShowedAppInstallBanner: () => {
    callProviderFunc("trackShowedAppInstallBanner");
  },
  trackClosedAppInstallBanner: () => {
    callProviderFunc("trackClosedAppInstallBanner");
  },
  trackAdCreationOnBoarding: step => {
    if (isNil(step)) {
      return;
    }

    callProviderFunc("trackAdCreationOnBoarding", step);
  },
  trackAdCreationOnBoardingClose: reason => {
    if (isNil(reason)) {
      return;
    }

    callProviderFunc("trackAdCreationOnBoardingClose", reason);
  },
};
