import {
  AnalyticsMe,
  AnalyticsProduct,
  AnalyticsProvider,
} from "@/common/contracts/analytics.contracts";
import generateIterableJwtQuery from "@/Apollo/mutation/generateIterableJwt.graphql";
import { ITERABLE_AUTH_TOKEN } from "@/config";
import {
  DisplayOptions,
  HandleLinks,
  WithJWT as IterableClientWithJWT,
  getInAppMessages,
  initializeWithConfig,
  track,
} from "@iterable/web-sdk";
import { createApolloClient } from "../../../../../lib/apollo";
import { log } from "next-axiom";

const isClient = typeof window !== "undefined";

let iterableClient: IterableClientWithJWT | undefined;
let iterableUserId: string | undefined;

if (isClient && !iterableClient) {
  iterableClient = initializeWithConfig({
    authToken: ITERABLE_AUTH_TOKEN || "",
    configOptions: {
      enableAnonActivation: true,
      isEuIterableService: true,
      onAnonUserCreated: userId => {
        log.info("Anonymous user created in Iterable", { userId });
      },
      identityResolution: {
        replayOnVisitorToKnown: true,
        mergeOnAnonymousToKnown: false,
      },
    },
    generateJWT: async ({ userID }) => {
      const client = createApolloClient();
      const { data } = await client.mutate({
        mutation: generateIterableJwtQuery,
        variables: { input: { userId: userID } },
      });
      return data?.generateIterableJwt.token || "";
    },
  });

  // Set the visitor usage tracked to true will send a usage event to Iterable
  iterableClient.setVisitorUsageTracked(true);
}

/**
 * Algolia's implementation of event tracking provider.
 */
export const iterableAnalyticsProvider: AnalyticsProvider = {
  identify: (me: AnalyticsMe) => {
    // We don't want to trigger identify again if the user id is the same.
    // TODO - Maybe move to base analytics service
    if (iterableUserId === me.id) {
      return;
    }

    // Keep track of the last user id.
    iterableUserId = me.id;

    const {
      request,
      // pauseMessageStream,
      // resumeMessageStream,
      // triggerDisplayMessages,
    } = getInAppMessages(
      {
        // Here, configure the SDK. For more information, check out the
        // web SDK's GitHub repository: https://github.com/iterable/iterable-web-sdk
        count: 20,
        displayInterval: 1000,
        onOpenScreenReaderMessage: "...",
        onOpenNodeToTakeFocus: "input",
        packageName: "whoppah",
        rightOffset: "20px",
        topOffset: "20px",
        bottomOffset: "20px",
        handleLinks: HandleLinks.ExternalNewTab,
        closeButton: {
          color: "black",
          size: "16px",
          topOffset: "20px",
        },
      },
      { display: DisplayOptions.Immediate }
    );

    // Set the email address, and make the request to get the in-app messages
    // that are available for that user. Display them automatically.
    iterableClient?.setUserID(me.id).then(() => {
      request().then().catch();
    });
  },
  trackLogout: () => {
    iterableClient?.logout();
  },
  trackViewItem: (product: AnalyticsProduct) => {
    track({
      eventName: "view_item",
      dataFields: {
        product,
      },
    });
  },
};
