import { useCallback, useEffect } from "react";

/**
 * Hook that allows reacting to "beforeunload" event with optional `prevent` function.
 */
const useBeforeUnload = (prevent?: () => boolean) => {
  const handleBeforeUnload = useCallback(
    (evt: BeforeUnloadEvent) => {
      if (prevent?.()) {
        evt.preventDefault();
        evt.returnValue = true;
      }
    },
    [prevent]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [handleBeforeUnload]);
};

export default useBeforeUnload;
