import { useStorage } from "@/common/hooks/storage.hooks";
import { useCallback, useEffect, useState } from "react";

type UseIsActiveOptions = {
  trackingKey: string;
  maxRepeatCount: number;
};

/**
 * Checks whether visit for a given session is unique or not.
 *
 * @param trackingKey - Unique identifier used to track visits.
 * @param maxRepeatCount - The maximum number of allowed unique visits. After this point, even unique visits will not be treated as such.
 */
export const useUniqueVisit = ({
  trackingKey,
  maxRepeatCount,
}: UseIsActiveOptions) => {
  const [isUnique, setIsUnique] = useState(false);

  const { load: loadSession, save: saveSession } = useStorage("session");
  const { load: loadLocal, save: saveLocal } = useStorage("local");

  useEffect(() => {
    const uniqueSessionKey = `${trackingKey}-unique`;
    const uniqueSession = !loadSession<boolean>(uniqueSessionKey);
    const uniqueCount = loadLocal<number>(trackingKey) ?? 0;

    if (!uniqueSession) {
      setIsUnique(false);
      return;
    }

    saveSession(uniqueSessionKey, true);

    if (uniqueCount >= maxRepeatCount) {
      setIsUnique(false);
      return;
    }

    saveLocal(trackingKey, uniqueCount + 1);
    setIsUnique(true);
  }, [
    loadLocal,
    loadSession,
    maxRepeatCount,
    saveLocal,
    saveSession,
    trackingKey,
  ]);

  const finalize = useCallback(() => {
    saveLocal(trackingKey, maxRepeatCount);
    setIsUnique(false);
  }, [maxRepeatCount, saveLocal, trackingKey]);

  return { isUnique, finalize };
};
