import { PostalPackageSize, ProductQuality, Shape } from "@/Apollo/schema";
import { getProductFitsInPostalPackage } from "@/features/create-ad/utils/create-ad.utils";
import { z } from "zod";

export const CATEGORIES_WHICH_HAVE_SHAPE = [
  "37709d15-1fcc-4cc7-b1a7-1ae3766413bc", //Tables
  "bd196361-81c0-46dc-9529-7bb500321c24", // Mirrors
  "69f906bd-bfa9-4c96-bb9f-8ff0c4982856", // Carpets
] as const;

export const ART_CATEGORY = "edbca868-64c6-4ac1-947a-a03c390626b1";

export const CATEGORIES_WHICH_HAVE_SEAT_HEIGHT = [
  "c55492d6-4580-4b06-a3b1-f2dd33e9363c", // Furniture - Chairs and lounge chairs
  "9264d056-00f0-4763-a00d-c77b9ad4900c", // Furniture - Sofas
  "0ea05d0f-6a01-44ea-aa7d-2ca4369fb373", // Furniture - Home office - Office chairs
  "36e8755d-4cec-4b8e-9c73-a0bc8b1c25bb", // Furniture - Home office - Conference chairs
  "b6c69035-5f66-46d0-8862-2888423076a8", // Furniture - Home office - Acoustic sofa
  "a3da84bf-3a20-4624-a84f-cf684790419b", // Furniture - Garden furniture - Garden chairs
  "7e7a41ed-49e1-4ba2-95bb-f6ca71c10a4e", // Furniture - Garden furniture - Lounge sets
  "9ad04450-b5e3-4de2-b421-910a8e27cb41", // Furniture - Garden furniture - Garden benches
] as const;

export const SHIPPING_METHOD_CUSTOM = "219c6f0f-5ed6-45cc-aeaf-7539a79e8b02";

export const SHIPPING_METHOD_POSTAL = "dbff0373-a4ee-4576-a24e-bcc7ea9ec7fe";

export const SHIPPING_METHOD_COURIER = "aa1bd039-164f-4e96-a8dd-29fde48d2006";

export const ALLOWED_PHOTO_MIME_TYPES = [
  "image/png",
  "image/jpeg",
  "image/heic",
  "placeholder",
] as const;

export const CreateAdWizardPhotosSchema = z
  .array(
    z.object({
      /** Identifies whether the photo is from the server or not. */
      id: z.string().nullish(),
      position: z.number().min(0).nullish(),
      /** Render key. */
      key: z.string(),
      /** Basic `File` data. */
      type: z.enum(ALLOWED_PHOTO_MIME_TYPES),
      /** Used for rendering (either object URL or actual HREF url). */
      url: z.string().nullish(),
      /** Actual file payload. */
      payload: z.any().nullish(),
    })
  )
  .refine(
    photos => {
      const actualPhotos = photos.filter(photo => photo.type !== "placeholder");

      return actualPhotos.length >= 5;
    },
    {
      message: "common:common-required",
    }
  )
  .nullable();

const CreateAdWizardSchemaObject = z
  .object({
    photos: CreateAdWizardPhotosSchema,
    title: z.string().max(128).nullable(),
    description: z.string().nullable(),
    numberOfItems: z
      .number()
      .int({ message: "create-ad:cp-overview-dimensions-whole-number" })
      .min(1)
      .nullable(),
    shapeCategory: z.enum(CATEGORIES_WHICH_HAVE_SHAPE).nullable(),
    artCategory: z.literal(ART_CATEGORY).nullable(),
    seatHeightCategory: z.enum(CATEGORIES_WHICH_HAVE_SEAT_HEIGHT).nullable(),
    category: z.string().uuid().nullable(),
    shape: z.nativeEnum(Shape).nullable(),
    brand: z.union([z.string().uuid(), z.literal("unknown")]).nullable(),
    brandSuggestion: z.string().nullable(),
    artist: z.union([z.string().uuid(), z.literal("unknown")]).nullable(),
    artistSuggestion: z.string().nullable(),
    colors: z.array(z.string().uuid()).nullable(),
    materials: z.array(z.string().uuid()).nullable(),
    dimensionsWidth: z
      .number()
      .int({ message: "create-ad:cp-overview-dimensions-whole-number" })
      .positive()
      .nullable(),
    dimensionsHeight: z
      .number()
      .int({ message: "create-ad:cp-overview-dimensions-whole-number" })
      .positive()
      .nullable(),
    dimensionsDepth: z
      .number()
      .int({ message: "create-ad:cp-overview-dimensions-whole-number" })
      .positive()
      .nullable(),
    dimensionsSeatHeight: z
      .number()
      .int({ message: "create-ad:cp-overview-dimensions-whole-number" })
      .positive()
      .nullable(),
    quality: z.nativeEnum(ProductQuality).nullable(),
    usageSigns: z.array(z.string().uuid()).nullable(),
    additionalInformation: z.array(z.string().uuid()).nullable(),
    buyPrice: z
      .number()
      .int({ message: "create-ad:cp-overview-dimensions-whole-number" })
      .positive()
      .min(20, { message: "create-ad:cp-overview-price-minimum-buy" })
      .nullable(),
    allowBidding: z.boolean().nullable(),
    minimumBid: z
      .number()
      .int({ message: "create-ad:cp-overview-dimensions-whole-number" })
      .positive()
      .nullable(),
    originalBuyPrice: z
      .number()
      .int({ message: "create-ad:cp-overview-dimensions-whole-number" })
      .positive()
      .nullable(),
    allowAutomatedDiscount: z.boolean().nullable(),
    shippingMethod: z
      .enum(
        [
          SHIPPING_METHOD_CUSTOM,
          SHIPPING_METHOD_POSTAL,
          SHIPPING_METHOD_COURIER,
        ],
        { invalid_type_error: "common:common-required" }
      )
      .nullable(),
    shippingMethodCost: z
      .number()
      .int({ message: "create-ad:cp-overview-dimensions-whole-number" })
      .nullable(),
    shippingPackageSize: z
      .nativeEnum(PostalPackageSize, {
        invalid_type_error: "common:common-required",
      })
      .nullable(),
    shippingPackageWeight: z
      .number()
      .int({ message: "create-ad:cp-overview-dimensions-whole-number" })
      .positive()
      .nullable(),
    shippingPackageFits: z.boolean().nullable(),
    shippingPickUp: z.boolean().nullable(),
    shippingAddress: z.string().uuid().nullable(),
  })
  .partial();

type CreateAdWizardSchemaObjectType = z.infer<
  typeof CreateAdWizardSchemaObject
>;

const validatePhotos = (
  { photos }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (!photos) {
    ctx.addIssue({
      path: ["photos"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

const validateTitle = (
  { title }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (!title) {
    ctx.addIssue({
      path: ["title"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

const validateDescription = (
  { description }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (!description) {
    ctx.addIssue({
      path: ["description"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

const validateNumberOfitems = (
  { numberOfItems }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (!numberOfItems) {
    ctx.addIssue({
      path: ["numberOfItems"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

const validateCategory = (
  { category }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (!category) {
    ctx.addIssue({
      path: ["category"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

const validateShape = (
  { shapeCategory, shape }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (shapeCategory && !shape) {
    ctx.addIssue({
      path: ["shape"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

const validateBrand = (
  { artCategory, brand, brandSuggestion }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (!artCategory && !brand && !brandSuggestion) {
    ctx.addIssue({
      path: ["brand"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

const validateArtist = (
  { artCategory, artist, artistSuggestion }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (artCategory && !artist && !artistSuggestion) {
    ctx.addIssue({
      path: ["artist"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

const validateColor = (
  { colors }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (!colors?.length) {
    ctx.addIssue({
      path: ["colors"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

const validateMaterial = (
  { materials }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (!materials?.length) {
    ctx.addIssue({
      path: ["materials"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

const validateQuality = (
  { quality }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (!quality) {
    ctx.addIssue({
      path: ["quality"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

const validateUsageSigns = (
  { quality, usageSigns }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (
    (quality == ProductQuality.Good || quality === ProductQuality.Great) &&
    !usageSigns?.length
  ) {
    ctx.addIssue({
      path: ["usageSigns"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

const validateDimensions = (
  {
    artCategory,
    seatHeightCategory,
    dimensionsWidth,
    dimensionsHeight,
    dimensionsDepth,
    dimensionsSeatHeight,
  }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (!dimensionsWidth) {
    ctx.addIssue({
      path: ["dimensionsWidth"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }

  if (!dimensionsHeight) {
    ctx.addIssue({
      path: ["dimensionsHeight"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }

  if (!artCategory && !dimensionsDepth) {
    ctx.addIssue({
      path: ["dimensionsDepth"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }

  if (seatHeightCategory && !dimensionsSeatHeight) {
    ctx.addIssue({
      path: ["dimensionsSeatHeight"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

const validatePrices = (
  { buyPrice, allowBidding, minimumBid }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (!buyPrice) {
    ctx.addIssue({
      path: ["buyPrice"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }

  if (allowBidding && !minimumBid) {
    ctx.addIssue({
      path: ["minimumBid"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }

  if (minimumBid && buyPrice && minimumBid < 0.1 * buyPrice) {
    ctx.addIssue({
      path: ["minimumBid"],
      code: z.ZodIssueCode.custom,
      message: "create-ad:cp-overview-price-minimum-bid",
    });
  }
};

const validateShipping = (
  {
    shippingMethod,
    shippingMethodCost,
    shippingPackageSize,
    shippingPackageFits,
    shippingAddress,
    dimensionsWidth,
    dimensionsHeight,
    dimensionsDepth,
  }: CreateAdWizardSchemaObjectType,
  ctx: z.RefinementCtx
) => {
  if (shippingMethod === SHIPPING_METHOD_CUSTOM && !shippingMethodCost) {
    ctx.addIssue({
      path: ["shippingMethodCost"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }

  /** Shipping cost of custom shipping must be above 0. */
  if (
    shippingMethod === SHIPPING_METHOD_CUSTOM &&
    shippingMethodCost &&
    shippingMethodCost <= 0
  ) {
    ctx.addIssue({
      path: ["shippingMethodCost"],
      code: z.ZodIssueCode.custom,
      message: "common:common-error-price-less-than",
    });
  }

  if (shippingMethod === SHIPPING_METHOD_POSTAL && !shippingPackageSize) {
    ctx.addIssue({
      path: ["shippingPackageSize"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }

  const fits = getProductFitsInPostalPackage({
    width: dimensionsWidth,
    height: dimensionsHeight,
    depth: dimensionsDepth,
  });

  if (
    shippingMethod === SHIPPING_METHOD_POSTAL &&
    !fits &&
    !shippingPackageFits
  ) {
    ctx.addIssue({
      path: ["shippingPackageFits"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }

  if (!shippingMethod) {
    ctx.addIssue({
      path: ["shippingMethod"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }

  if (!shippingAddress) {
    ctx.addIssue({
      path: ["shippingAddress"],
      code: z.ZodIssueCode.custom,
      message: "common:common-required",
    });
  }
};

export const CreateAdWizardSchema = CreateAdWizardSchemaObject.superRefine(
  (data, ctx) => {
    /** At least 5 photos are required. */
    validatePhotos(data, ctx);

    /** Title is required. */
    validateTitle(data, ctx);

    /** Description is required. */
    validateDescription(data, ctx);

    /** Number of items is required, minimum 1. */
    validateNumberOfitems(data, ctx);

    /** Category is required. */
    validateCategory(data, ctx);

    /** Shape is required if category is "table", "mirror" or "carpet". */
    validateShape(data, ctx);

    /** Brand/suggestion is required if category is NOT Art. */
    validateBrand(data, ctx);

    /** Artist/suggestion is required if category is Art. */
    validateArtist(data, ctx);

    /** Color is required. */
    validateColor(data, ctx);

    /** Material is required. */
    validateMaterial(data, ctx);

    /** Quality is required. */
    validateQuality(data, ctx);

    /** Usage signs are required if quality is "good" or "great". */
    validateUsageSigns(data, ctx);

    /** Width is required. */
    /** Height is required. */
    /** Depth is required if category is NOT "art". */
    /** Seat height is required if category is "furniture". */
    validateDimensions(data, ctx);

    /** Buy price is required. */
    /** Minimum bid price is required if bidding is enabled. */
    validatePrices(data, ctx);

    /** Custom shipping price is required if "Custom" shipping method. */
    /** Postal package size is required if "Postal" shipping method. */
    /** Package fits is required if dimensions are beyond max package size. */
    /** Shipping address is required. */
    validateShipping(data, ctx);
  }
);

export const DimensionsSchema = CreateAdWizardSchemaObject.pick({
  artCategory: true,
  seatHeightCategory: true,
  dimensionsWidth: true,
  dimensionsHeight: true,
  dimensionsDepth: true,
  dimensionsSeatHeight: true,
}).superRefine((data, ctx) => {
  /** Width is required. */
  /** Height is required. */
  /** Depth is required if category is NOT "art". */
  /** Seat height is required if category is "furniture". */
  validateDimensions(data, ctx);
});

export const PricesSchema = CreateAdWizardSchemaObject.pick({
  buyPrice: true,
  allowBidding: true,
  minimumBid: true,
  originalBuyPrice: true,
  allowAutomatedDiscount: true,
}).superRefine((data, ctx) => {
  /** Buy price is required. */
  /** Minimum bid price is required if bidding is enabled. */
  validatePrices(data, ctx);
});

export const ShippingSchema = CreateAdWizardSchemaObject.pick({
  dimensionsWidth: true,
  dimensionsHeight: true,
  dimensionsDepth: true,
  shippingMethod: true,
  shippingMethodCost: true,
  shippingPackageSize: true,
  shippingPackageWeight: true,
  shippingPackageFits: true,
  shippingPickUp: true,
  shippingAddress: true,
}).superRefine((data, ctx) => {
  /** Custom shipping price is required if "Custom" shipping method. */
  /** Postal package size is required if "Postal" shipping method. */
  /** Package fits is required if dimensions are beyond max package size. */
  /** Shipping address is required. */
  validateShipping(data, ctx);
});
