import paymentIdeal from "../../../assets/svgs/payment-ideal.svg";
import paymentBancontact from "../../../assets/svgs/payment-bancontact.svg";
import paymentVisa from "../../../assets/svgs/payment-visa.svg";
import paymentMastercard from "../../../assets/svgs/payment-mastercard.svg";
import paymentMaestro from "../../../assets/svgs/payment-maestro.svg";
import paymentAmex from "../../../assets/svgs/payment-amex.svg";
import paymentApple from "../../../assets/svgs/payment-apple-pay.svg";
import paymentPaypal from "../../../assets/svgs/payment-paypal.svg";
import paymentBancaires from "../../../assets/svgs/payment-cartes-bancaires.svg";
import paymentGiropay from "../../../assets/svgs/payment-giropay.svg";

export const PAYMENT_METHODS = [
  { image: paymentIdeal, name: "iDEAL" },
  { image: paymentBancontact, name: "Bancontact" },
  { image: paymentPaypal, name: "Paypal" },
  { image: paymentVisa, name: "Visa" },
  { image: paymentMastercard, name: "Mastercard" },
  { image: paymentMaestro, name: "Maestro" },
  { image: paymentAmex, name: "American express" },
  { image: paymentApple, name: "Apple pay" },
  { image: paymentBancaires, name: "Cartes bancaires" },
  { image: paymentGiropay, name: "Giropay" },
];
