import { Tag as TagIcon } from "@/assets/icons/24/Tag";
import { useCreateAdNavigator } from "@/features/create-ad/hooks/useCreateAdNavigator";
import { Button } from "@/ui/cva/Button";
import { useTranslation } from "next-i18next";

/**
 * Header create ad button. Navigates user to create ad wizard.
 */
export const HeaderCreateAd = () => {
  const { t } = useTranslation();
  const { gotoWizard } = useCreateAdNavigator();

  return (
    <>
      {/* Desktop */}
      <Button
        type="button"
        size="large"
        rightIcon={<TagIcon />}
        className="hidden lg:flex"
        onClick={gotoWizard}
      >
        {t("header:nav-sell-now")}
      </Button>
      {/* Tablet */}
      <Button
        type="button"
        rightIcon={<TagIcon />}
        className="hidden self-stretch md:flex lg:hidden"
        onClick={gotoWizard}
      />
      {/* Mobile */}
      <Button
        type="button"
        size="small"
        className="md:hidden"
        onClick={gotoWizard}
      >
        {t("header:header-nav-sell")}
      </Button>
    </>
  );
};
