import { isBrowser } from "@/common/hooks/general.hooks";
import { useCallback } from "react";

export const useStorage = (type: "local" | "session") => {
  const source = type === "local" ? "localStorage" : "sessionStorage";

  const load = useCallback(
    <T>(key: string) => {
      if (!isBrowser()) {
        return;
      }

      const data = window[source].getItem(key);

      if (!data) {
        return;
      }

      return JSON.parse(data) as T;
    },
    [source]
  );

  const save = useCallback(
    <T>(key: string, data: T) => {
      if (!isBrowser()) {
        return;
      }

      window[source].setItem(key, JSON.stringify(data));
    },
    [source]
  );

  return { load, save };
};
