import { IconProps } from "@/common/contracts/icon.contracts";

export const ArrowRight = (props: IconProps) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.02679 13.0537H16.4961L11.4853 18.1956C11.0849 18.6065 11.0849 19.2809 11.4853 19.6918C11.8858 20.1027 12.5326 20.1027 12.9331 19.6918L19.6997 12.7481C20.1001 12.3372 20.1001 11.6734 19.6997 11.2624L12.9434 4.30818C12.7515 4.11088 12.4911 4 12.2195 4C11.9479 4 11.6874 4.11088 11.4956 4.30818C11.0951 4.71912 11.0951 5.38293 11.4956 5.79386L16.4961 10.9463H5.02679C4.46206 10.9463 4 11.4205 4 12C4 12.5795 4.46206 13.0537 5.02679 13.0537Z"
        fill="currentColor"
      />
    </svg>
  );
};
